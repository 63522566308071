import type { ErrorMessage } from '@pin/schemas/clientSchema';
import type {
  CreateLeaguePayload,
  League,
  LeagueMemberInfo,
  SimpleLeague,
} from '@pin/schemas/leagueSchema';
import {
  BehaviorSubject,
  Subject,
  distinctUntilChanged,
  filter,
  from,
  lastValueFrom,
  map,
  mergeMap,
  of,
  shareReplay,
  startWith,
  switchMap,
  throwError,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { getFreshSession$ } from '../components/Login/loginSessionStore';
import {
  catchHttpErrors,
  checkNoContentSuccess,
  jsonSuccessSelector,
  retryFetch,
} from '../util/rxjs.http.js';
import { getClientId } from './clientId';
import { clientState$ } from './clientState';

export const createLeague = async (payload: CreateLeaguePayload) => {
  return await lastValueFrom(
    from(getFreshSession$).pipe(
      switchMap((session) =>
        session?.accessToken != null
          ? fromFetch(
              `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/leagues`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${session.accessToken}`,
                },
                body: JSON.stringify(payload),
                selector: jsonSuccessSelector<SimpleLeague>,
              }
            ).pipe(timeout(6_000), retryFetch(4))
          : // If accessToken is null there might be some issues with innlogging
            // so we wait 5 seconds to not give the retry option to the user too early.
            timer(5_000).pipe(
              mergeMap(() => throwError(() => new Error('No token')))
            )
      )
    )
  );
};

export const shouldFetchLeagues = new BehaviorSubject<{
  shouldFetch: boolean;
  cacheRefresh: boolean;
}>({ shouldFetch: true, cacheRefresh: false });
export const fetchLeaguesSubject = new Subject<{ cacheRefresh: boolean }>();
const leaguesObservable = () =>
  fetchLeaguesSubject.pipe(
    switchMap(({ cacheRefresh }) =>
      from(getFreshSession$).pipe(
        switchMap((session) =>
          clientState$.pipe(
            map(({ poster }) => poster?.level !== 'Error'),
            filter((v) => v),
            distinctUntilChanged(),
            switchMap(() =>
              session?.accessToken != null
                ? fromFetch(
                    `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/leagues`,
                    {
                      headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${session.accessToken}`,
                      },
                      cache:
                        cacheRefresh || shouldFetchLeagues
                          ? 'reload'
                          : 'default',
                      selector: jsonSuccessSelector<League[]>,
                    }
                  ).pipe(timeout(6_000), retryFetch(4))
                : of([])
            )
          )
        )
      )
    )
  );

export const leagues$ = leaguesObservable().pipe(
  shareReplay({ bufferSize: 1, refCount: false }),
  startWith(null)
);

export const getLeague = async (leagueId: string) => {
  return await lastValueFrom(
    fromFetch(
      `${import.meta.env.VITE_API_URL}/api/${getClientId()}/leagues/${leagueId}`,
      {
        headers: {
          Accept: 'application/json',
        },
        selector: jsonSuccessSelector<SimpleLeague>,
      }
    ).pipe(timeout(6_000), retryFetch(4))
  );
};

export const getLeagueMemberInfo = async (leagueId: string) => {
  return await lastValueFrom(
    from(getFreshSession$).pipe(
      switchMap((session) =>
        session?.accessToken != null
          ? fromFetch(
              `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/leagues/${leagueId}/members/info`,
              {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${session.accessToken}`,
                },
                selector: jsonSuccessSelector<LeagueMemberInfo>,
              }
            ).pipe(timeout(6_000), retryFetch(4))
          : // If accessToken is null there might be some issues with innlogging
            // so we wait 5 seconds to not give the retry option to the user too early.
            timer(5_000).pipe(
              mergeMap(() => throwError(() => new Error('No token')))
            )
      )
    )
  );
};

export const addMemberToLeague = async (
  leagueId: string
): Promise<Response | ErrorMessage> => {
  return await lastValueFrom(
    from(getFreshSession$).pipe(
      switchMap((session) =>
        session?.accessToken != null
          ? fromFetch(
              `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/leagues/${leagueId}/members`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${session.accessToken}`,
                },
              }
            ).pipe(
              checkNoContentSuccess(),
              timeout(6_000),
              catchHttpErrors<ErrorMessage>([403]),
              retryFetch(4)
            )
          : // If accessToken is null there might be some issues with innlogging
            // so we wait 5 seconds to not give the retry option to the user too early.
            timer(5_000).pipe(
              mergeMap(() => throwError(() => new Error('No token')))
            )
      )
    )
  );
};

export const leaveLeague = async (leagueId: string) => {
  return await lastValueFrom(
    from(getFreshSession$).pipe(
      switchMap((session) =>
        session?.accessToken != null
          ? fromFetch(
              `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/leagues/${leagueId}/members`,
              {
                method: 'DELETE',
                headers: {
                  Authorization: `Bearer ${session.accessToken}`,
                },
              }
            ).pipe(checkNoContentSuccess(), timeout(6_000), retryFetch(4))
          : // If accessToken is null there might be some issues with innlogging
            // so we wait 5 seconds to not give the retry option to the user too early.
            timer(5_000).pipe(
              mergeMap(() => throwError(() => new Error('No token')))
            )
      )
    )
  );
};
