import type { AnswerResponse } from '@pin/schemas/clientSchema';
import {
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { getFreshSession$ } from '../components/Login/loginSessionStore';
import { jsonSuccessSelector, retryFetch } from '../util/rxjs.http.js';
import { trackEvent } from '../util/snowplow.js';
import { getClientId } from './clientId.js';
import { clientState$ } from './clientState';

export const fetchAnswerSubject = new Subject<{ cacheRefresh: boolean }>();
const answerAndScoreObservable = ({
  retry = 3,
  timeout: timeoutMs = 6_000,
  errorDelay = 5_000,
}) =>
  fetchAnswerSubject.pipe(
    switchMap(({ cacheRefresh }) =>
      from(getFreshSession$).pipe(
        switchMap((session) =>
          clientState$.pipe(
            map(({ poster }) => poster?.level !== 'Error'),
            filter((v) => v),
            distinctUntilChanged(),
            switchMap(() =>
              session?.accessToken != null
                ? fetchAnswer(session.accessToken, cacheRefresh).pipe(
                    timeout(timeoutMs),
                    retryFetch(retry),
                    catchError((_err: unknown, source) => {
                      trackEvent({ action: 'error:fetching-answers' });
                      return timer(errorDelay).pipe(switchMap(() => source));
                    })
                  )
                : of([])
            )
          )
        )
      )
    )
  );

const fetchAnswer = (accessToken: string, cacheRefresh: boolean) =>
  fromFetch(
    `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/answers`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      cache: cacheRefresh ? 'reload' : 'default',
      selector: jsonSuccessSelector<AnswerResponse>,
    }
  );

export const userAnswers$ = answerAndScoreObservable({}).pipe(
  shareReplay({ bufferSize: 1, refCount: false })
);
