<script lang="ts">
  import {
    nrkChevronDown,
    nrkSpinner,
    nrkTrash,
    nrkUserLoggedinActive,
  } from '@nrk/core-icons';
  import type { League } from '@pin/schemas/leagueSchema';
  import * as Sentry from '@sentry/svelte';
  import { compareAsc } from 'date-fns';
  import { clientState$ } from '../../api/clientState.js';
  import {
    fetchLeaguesSubject,
    getLeagueMemberInfo,
    leaveLeague,
  } from '../../api/fetchleague';
  import { impression, trackEvent } from '../../util/snowplow.js';
  import Scoreboard from '../Scores/Scoreboard.svelte';
  import CoreToggle from '../core/CoreToggle.svelte';
  import InviteMembersButton from './InviteMembersButton.svelte';

  export let league: League;
  export let color: string;

  let leaveLoading = false;
  let error = false;
  const handleLeaveLeague = async () => {
    try {
      error = false;
      leaveLoading = true;
      trackEvent({ action: 'click:leave-league' });
      await leaveLeague(league.id);
      fetchLeaguesSubject.next({ cacheRefresh: true });
    } catch (e) {
      Sentry.captureException(e);
      setTimeout(() => {
        error = false;
      }, 5_000);
      error = true;
    } finally {
      leaveLoading = false;
    }
  };

  let showTotal = true;
  let expandButton: HTMLButtonElement;

  const handleClick = async (league: League) => {
    // Selv om dette skal skje når toggelen er utvidet, så skjer dette før ariaExpanded settes til true.
    if (expandButton.ariaExpanded === 'false') {
      const { count } = await getLeagueMemberInfo(league.id);
      if (count !== league.members.length) {
        fetchLeaguesSubject.next({ cacheRefresh: true });
      }
    }
  };

  $: totalScoreboard = league.members
    .slice()
    .sort((a, b) => {
      if (b.total_score !== a.total_score) {
        return b.total_score - a.total_score;
      }
      return compareAsc(a.joinedAt, b.joinedAt);
    })
    .map((u, i) => ({
      isCurrentUser: u.isCurrentUser,
      score: u.total_score,
      rank: i + 1,
      name: u.name,
      type: 'TOTAL',
    }));

  $: isAfter = $clientState$.state === 'After';
  $: personalScore = totalScoreboard.find(({ isCurrentUser }) => isCurrentUser);
</script>

<div class="container">
  <div class="league-wrapper" class:isAfter style="--color: var(--{color})">
    <button
      class="expand-button"
      type="button"
      data-for={`league-${league.id}`}
      bind:this={expandButton}
      on:click={() => handleClick(league)}
    >
      <span class="league-name-wrapper">
        <span class="center icon">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {@html nrkUserLoggedinActive}
        </span>
        <p class="league-name">{league.name}</p>
      </span>

      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </button>
    {#if isAfter && personalScore !== undefined}
      <div class="rank-wrapper">
        <span class="rank"> Nr. {personalScore.rank}</span> av {league.members
          .length}
      </div>
    {/if}
    <CoreToggle id={`league-${league.id}`} closeOnOutsideClick={false}>
      <u-tabs use:impression={'league'}>
        <u-tablist>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <u-tab
            aria-selected={!showTotal}
            on:click={() => {
              showTotal = false;
              trackEvent({ action: 'click:view-league-current' });
            }}
            aria-controls={`tab-today-${league.id}`}>Resultater</u-tab
          >
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <u-tab
            aria-selected={showTotal}
            on:click={() => {
              showTotal = true;
              trackEvent({ action: 'click:view-league-total' });
            }}
            aria-controls={`tab-total-${league.id}`}>Sammenlagt</u-tab
          >
        </u-tablist>

        <u-tabpanel id={`tab-today-${league.id}`}>
          <h3>
            {$clientState$.title}
          </h3>
          <Scoreboard
            type="league-today"
            scoreboard={league.members
              .slice()
              .sort((a, b) => {
                if (b.current_score !== a.current_score) {
                  return b.current_score - a.current_score;
                }
                return compareAsc(a.joinedAt, b.joinedAt);
              })
              .map((u, i) => ({
                isCurrentUser: u.isCurrentUser,
                score: u.current_score,
                rank: i + 1,
                name: u.name,
                type: 'CURRENT',
              }))}
          />
        </u-tabpanel>
        <u-tabpanel id={`tab-total-${league.id}`}>
          <div class="spacer"></div>
          <Scoreboard
            type="league-total"
            scoreboard={league.members
              .slice()
              .sort((a, b) => {
                if (b.total_score !== a.total_score) {
                  return b.total_score - a.total_score;
                }
                return compareAsc(a.joinedAt, b.joinedAt);
              })
              .map((u, i) => ({
                isCurrentUser: u.isCurrentUser,
                score: u.total_score,
                rank: i + 1,
                name: u.name,
                type: 'TOTAL',
              }))}
          />
        </u-tabpanel>
      </u-tabs>
      <p class="member-count">
        {`${league.members.length} deltaker${league.members.length === 1 ? '' : 'e'}`}
      </p>
      <div class="actions">
        <InviteMembersButton {league} />
        <button
          class="leave-league"
          class:error
          on:click={handleLeaveLeague}
          disabled={leaveLoading}
        >
          {#if error}
            <span aria-live="assertive"> Prøv igjen </span>
          {:else}
            <span>
              <!-- eslint-disable-next-line svelte/no-at-html-tags -->
              {@html nrkTrash}
            </span>
            Forlat liga
            {#if leaveLoading}
              <span class="inline-spinner">
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html nrkSpinner}
              </span>
            {/if}
          {/if}
        </button>
      </div>
    </CoreToggle>
  </div>
</div>

<style>
  :root {
    --red: linear-gradient(180deg, #1c0001 0%, #820000 80%, #1b0002 100%);
    --yellow: linear-gradient(180deg, #1c0001 0%, #824c00 80%, #1b0002 100%);
    --green: linear-gradient(180deg, #1c0001 0%, #5f8200 80%, #1b0002 100%);
    --blue-green: linear-gradient(
      180deg,
      #1c0001 0%,
      #008241 80%,
      #1b0002 100%
    );
    --cyan: linear-gradient(180deg, #1c0001 0%, #00827b 80%, #1b0002 100%);
    --blue: linear-gradient(180deg, #1c0001 0%, #005b82 80%, #1b0002 100%);
    --dark-blue: linear-gradient(180deg, #1c0001 0%, #002d82 80%, #1b0002 100%);
    --purple: linear-gradient(180deg, #1c0001 0%, #7f0082 80%, #1b0002 100%);
    --pink: linear-gradient(180deg, #1c0001 0%, #82003f 80%, #1b0002 100%);
    --orange: linear-gradient(180deg, #1c0001 0%, #ba5700 80%, #1b0002 100%);
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .league-wrapper {
    background: #1c0001;
    width: 100%;
  }

  .league-wrapper.isAfter {
    border: 0.125rem solid #a05c0e;
    background: black;
  }
  .league-wrapper {
    :global(nrk-toggle) {
      background: var(--color);
    }
  }

  .expand-button {
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1.313rem;
    font-weight: 800;
    letter-spacing: 0.0262rem;
    padding: 1rem;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  h3 {
    font-size: 1rem;
    margin-top: 1rem;
  }

  .spacer {
    /* Same height as the h3 */
    height: 2.1875rem;
  }

  .rank-wrapper {
    padding: 0 1rem 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: -0.01em;
  }
  .rank {
    color: #ffa90a;
  }

  .icon {
    transform: scale(0.65);
  }

  .league-name-wrapper {
    display: inline-flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .league-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .leave-league {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.125rem;
    color: #ff9e8f;
    border: 0.0625rem solid hsla(8, 100%, 78%, 0.5);
    font-weight: 800;
    font-size: 1rem;
    padding: 0 1rem;
    height: 3rem;
    margin: 1rem 0 2rem;
  }

  .leave-league span {
    display: inline-flex;
  }

  button.error {
    background: var(--NRK-cool-orange-400, #feb937);
    color: var(--NRK-black, #000);
  }

  .member-count {
    color: var(--NRK-gray-100, #ebe7e6);
    font-variation-settings: var(--nrk-sans-width-semi-condensed);
    font-weight: 700;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  u-tabs {
    width: 100%;
    margin-top: 2rem;
  }
  u-tablist {
    display: flex;
    padding: 0 1rem;
  }
  u-tab {
    display: grid;
    place-content: center;
    padding: 0.5625rem var(--300, 1rem);
    width: 50%;
    font-weight: 560;
    letter-spacing: -0.01rem;
    background: #472527;
    color: #ff9e8f;
  }

  u-tab:nth-child(1) {
    border-radius: 0.625rem 0 0 0.625rem;
  }
  u-tab:nth-child(2) {
    border-radius: 0 0.625rem 0.625rem 0;
  }
  u-tab[aria-selected='true'] {
    color: #131317;
    background: #ffa90a;
  }
</style>
