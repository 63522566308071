<script lang="ts">
  import { shouldOpenLeaugeDialog } from '../stores/openLeagueDialog';
  import selectedTabStore, { tabs } from '../stores/selectedTabStore';
  import { trackEvent } from '../util/snowplow.js';
  import { loginDialogStore } from './Dialogs/loginDialogStore.js';
  import { loginSessionStore } from './Login/loginSessionStore.js';

  const handleClick = () => {
    trackEvent({ action: 'click:create-league-before-cards' });
    if ($loginSessionStore?.isLoggedIn === false) {
      $loginDialogStore?.show();
    } else {
      $selectedTabStore = tabs[1];
      shouldOpenLeaugeDialog.set(true);
    }
  };
</script>

<div class="non-question-card card">
  <slot></slot>
  <img src="podium.svg" alt="podium" />
  <button class="primary-button" on:click={handleClick}>Lag venneliga</button>
</div>

<style>
  img {
    height: 5.6875rem;
  }
  .primary-button {
    color: var(--NRK-cool-orange-500, #ffa90a);
    text-align: center;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 0.25rem;
    height: 2.4375rem;
    display: flex;
    padding: var(--200, 0.5rem);
    align-items: center;
    gap: var(--300, 1rem);
    background: rgba(255, 255, 255, 0.14);
  }
</style>
