<script lang="ts">
  import type { AnswerHistory } from '@pin/schemas/clientSchema';

  export let question: AnswerHistory['history'][number]['questions'][number];
  export let isLast = false;
</script>

<div class="wrapper">
  <div class="question-text">{question.text}</div>
  <div class="points" class:golden={question.score}>
    {question.score ? question.score : 0}p
  </div>
  <div class="guess">
    {question.answer != undefined
      ? `Du svarte: ${question.answer}`
      : 'Du svarte ikke'}
  </div>
  <div class="solution">
    {question.solution !== null
      ? `Riktig svar: ${question.solution}`
      : 'Ingen fasit enda'}
  </div>
  {#if !isLast}
    <div class="line"></div>
  {/if}
</div>

<style>
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 3.125rem;
    justify-items: start;
    text-align: left;
    gap: 0.25rem;
  }

  .guess,
  .solution,
  .line {
    grid-column: 1/-1;
  }

  .question-text {
    font-weight: 560;
  }

  .question-text,
  .points {
    margin-top: 0.8rem;
  }

  .guess,
  .solution {
    font-style: italic;
  }

  .points {
    justify-self: end;
    font-weight: 800;
  }

  .golden {
    color: var(--NRK-cool-orange-500, #ffa90a);
  }

  .line {
    margin-top: 1rem;
    opacity: 0.2;
    background: var(--NRK-gray-100, #ebe7e6);
    height: 0.0625rem;
    width: 100%;
  }
</style>
