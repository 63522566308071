<script lang="ts">
  import type { Question } from '@pin/schemas/clientSchema';

  export let question: Question;
  export let isLast = false;

  $: userGuess =
    question.type === 'MultipleChoice'
      ? question.answer?.alternative.text
      : question.answer?.value;
  $: solution =
    question.type === 'MultipleChoice'
      ? question.alternatives.find(
          (q) => question.solution && q.id === question.solution
        )?.text
      : question.solution;
</script>

<div class="wrapper">
  <div class="question-text">{question.text}</div>
  <div class="points" class:golden={question.answer?.score}>
    {question.answer?.score ? question.answer?.score : 0}p
  </div>
  <div class="guess">
    {userGuess != undefined ? `Du svarte: ${userGuess}` : 'Du svarte ikke'}
  </div>
  <div class="solution">Riktig svar: {solution}</div>
  {#if !isLast}
    <div class="line"></div>
  {/if}
</div>

<style>
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 3.125rem;
    justify-items: start;
    text-align: left;
    gap: 0.25rem;
  }

  .guess,
  .solution,
  .line {
    grid-column: 1/-1;
  }

  .question-text {
    font-weight: 560;
  }

  .question-text,
  .points {
    margin-top: 0.8rem;
  }

  .guess,
  .solution {
    font-style: italic;
  }

  .points {
    justify-self: end;
    font-weight: 800;
  }

  .golden {
    color: var(--NRK-cool-orange-500, #ffa90a);
  }

  .line {
    margin-top: 1rem;
    opacity: 0.2;
    background: var(--NRK-gray-100, #ebe7e6);
    height: 0.0625rem;
    width: 100%;
  }
</style>
