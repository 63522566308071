<script>
  export let big = false;
</script>

{#if big}
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87"
    height="24"
    viewBox="0 0 87 24"
    fill="currentColor"
  >
    <path
      d="M84.7745 12C84.7745 18.6274 79.5951 24 73.2061 24C66.8171 24 61.6377 18.6274 61.6377 12C61.6377 5.37258 66.8171 0 73.2061 0C79.5951 0 84.7745 5.37258 84.7745 12Z"
    />
    <path d="M85.4975 0H86.2205V24H85.4975V0Z" />
    <path d="M60.1917 0H60.9147V24H60.1917V0Z" />
    <path
      d="M1.26529 0H50.7649C51.8755 0 52.8549 0.36 53.7033 1.08C54.5516 1.8 55.0838 2.72 55.2997 3.84L59.6494 24H6.49419L1.26529 0Z"
    />
    <path d="M0 0H0.723023V24H0V0Z" />
  </svg>
{:else}
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="14"
    viewBox="0 0 51 14"
    fill="currentColor"
  >
    <path
      d="M49.6954 7C49.6954 10.866 46.6592 14 42.9139 14C39.1686 14 36.1325 10.866 36.1325 7C36.1325 3.13401 39.1686 0 42.9139 0C46.6592 0 49.6954 3.13401 49.6954 7Z"
    />
    <path d="M50.1192 0H50.5431V14H50.1192V0Z" />
    <path d="M35.2848 0H35.7086V14H35.2848V0Z" />
    <path
      d="M0.741722 0H29.7587C30.4098 0 30.9839 0.21 31.4812 0.63C31.9785 1.05 32.2905 1.58667 32.4171 2.24L34.9669 14H3.80694L0.741722 0Z"
    />
    <path d="M0 0H0.423841V14H0V0Z" />
  </svg>
{/if}
