<script lang="ts">
  import type { ClientState } from '@pin/schemas/clientSchema';
  import { format } from 'date-fns';
  import { nb } from 'date-fns/locale';
  import { fromEvent, type Subscription } from 'rxjs';
  import { first } from 'rxjs/operators';
  import { onDestroy, onMount } from 'svelte';
  import { flip } from 'svelte/animate';
  import { quintOut } from 'svelte/easing';
  import { crossfade } from 'svelte/transition';
  import { clientState$ } from '../api/clientState.js';
  import { history$ } from '../api/history.js';
  import { showToast } from '../api/newQuestions.js';
  import { fetchTotalScore, shouldFetchTotalScore } from '../api/totalScore';
  import {
    hasSolution,
    isAnswered,
    isClosed,
    isRevealed,
    readyForReveal,
  } from '../util/questionState.js';
  import { currentQuestionId } from '../util/questionStateStore.js';
  import { currentRevealQuestionId } from '../util/revealQuestion.js';
  import { getItem, openedQuestionKey, removeItem } from '../util/storage.js';
  import AfterCards from './AfterCards.svelte';
  import BeforeCards from './BeforeCards.svelte';
  import type { DialogCloseEvent } from './core/CoreDialog.svelte';
  import CoreDialog from './core/CoreDialog.svelte';
  import CreateLeagueCard from './CreateLeagueCard.svelte';
  import AnsweredDialog from './Dialogs/AnsweredDialog.svelte';
  import GoldenDialog from './Dialogs/GoldenDialog.svelte';
  import UnansweredDialog from './Dialogs/UnansweredDialog.svelte';
  import QuestionHistory from './History/QuestionHistory.svelte';
  import TodaysQuestionHistory from './History/TodaysQuestionHistory.svelte';
  import AnsweredQuestionCard from './Questions/AnsweredQuestionCard.svelte';
  import GoldCard from './Questions/GoldCard.svelte';
  import QuestionCard from './Questions/QuestionCard.svelte';
  import Shelf from './Shelf.svelte';
  import SvgClock from './SVGClock.svelte';

  const cardAnimationDuration = 350;
  export let state: ClientState;
  let questionDialog: CoreDialog;
  let revealDialog: CoreDialog;
  let openHeading: HTMLHeadingElement | undefined;
  let focusSubscription: Subscription | null = null;

  const [send, receive] = crossfade({
    fallback(node, _params) {
      const style = getComputedStyle(node);
      const transform = style.transform === 'none' ? '' : style.transform;

      return {
        duration: cardAnimationDuration,
        easing: quintOut,
        css: (t) => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`,
      };
    },
  });

  // open && not answered
  $: open = state.questions.filter(
    (q) => !isClosed(q) && q.answer == undefined
  );

  // Closed && no-solution || answered && no-solution
  $: answeredOrClosed = state.questions
    .filter(
      (q) =>
        (!hasSolution(q) && isAnswered(q)) || (!hasSolution(q) && isClosed(q))
    )
    .slice()
    .reverse();

  let prevAnswered = 0;
  $: answered = state.questions.filter(isAnswered).length;
  $: {
    if (answered > prevAnswered) {
      showToast.set(false);
    }
    prevAnswered = answered;
  }
  $: if (open.length === 0) showToast.set(false);

  $: questionsReadyForReveal = state.questions.filter((q) => readyForReveal(q));

  // history = revealedAt != null || closed && noAnswer && correctAnswer
  $: history = state.questions
    .filter(
      (q) => isRevealed(q) || (isClosed(q) && !isAnswered(q) && hasSolution(q))
    )
    .slice()
    .reverse();

  let shouldRefetchScore = false;
  $: if (questionsReadyForReveal.length === 0 && shouldRefetchScore) {
    fetchTotalScore.next({ cacheRefresh: true });
    shouldRefetchScore = false;
    shouldFetchTotalScore.next({ shouldFetch: false, cacheRefresh: false });
  }

  $: if (questionsReadyForReveal.length > 0) {
    shouldRefetchScore = true;
  }

  const closeQuestionDialog = (e: CustomEvent<DialogCloseEvent>) => {
    if (e.detail?.openingElementWillBeRemoved) {
      const activeElement = document.activeElement as HTMLElement;

      if (activeElement) {
        // Cleanup any previous subscription
        focusSubscription?.unsubscribe();

        // Create a subscription for the focusout event
        focusSubscription = fromEvent(activeElement, 'focusout')
          .pipe(first()) // Automatically unsubscribe after the first focusout
          .subscribe(() => {
            const button = document.querySelector<HTMLButtonElement>(
              `.open-question-button[data-id="${open[0]?.id}"]`
            );
            if (button) {
              button.focus();
            } else {
              openHeading?.focus();
            }
          });
      }
    }

    $currentQuestionId = '';
  };

  onDestroy(() => {
    focusSubscription?.unsubscribe();
  });

  onMount(() => {
    const openedQuestionId = getItem<string>('session', openedQuestionKey);
    if (openedQuestionId != null) {
      removeItem('session', openedQuestionKey);
      if (open.some(({ id }) => openedQuestionId === id)) {
        currentQuestionId.set(openedQuestionId);
        questionDialog.show();
      }
    }
  });
</script>

<h2 class="pill-h2" tabindex="-1" bind:this={openHeading}>
  {$clientState$.title}
</h2>
{#if $clientState$.poster?.level === 'Survey'}
  <div class="survey">
    <div class="survey-title">
      {$clientState$.poster.title}
    </div>
    <div class="survey-description">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html $clientState$.poster.description}
    </div>

    <a class="primary-button" href={$clientState$.poster.url} target="_blank"
      >Svar på undersøkelsen</a
    >
  </div>
{:else if $clientState$.state === 'After'}
  <AfterCards />
{:else if $clientState$.questions.length === 0 && history.length === 0 && !$history$?.history?.length}
  <BeforeCards />
{:else if $clientState$.nextQuestionBatchAt === null && open.length === 0}
  <AfterCards noMoreQuestions={true} />
{:else if open.length > 0}
  <Shelf numberOfDots={open.length}>
    {#each open as question (question.id)}
      <div
        in:receive={{ key: question.id }}
        out:send={{ key: question.id }}
        animate:flip={{ duration: cardAnimationDuration }}
      >
        <QuestionCard {questionDialog} {question} />
      </div>
    {/each}
  </Shelf>
{:else}
  <Shelf numberOfDots={2}>
    {#if $clientState$.nextQuestionBatchAt}
      <div class="non-question-card card">
        Nye spørsmål kommer ca.
        <SvgClock
          hour={format($clientState$.nextQuestionBatchAt, 'H')}
          minute={format($clientState$.nextQuestionBatchAt, 'm')}
        />
        <span class="time">
          {format($clientState$.nextQuestionBatchAt, 'HH:mm', {
            locale: nb,
          })}
        </span>
      </div>
    {:else}
      <div class="non-question-card card">
        Ingen nye spørsmål for øyeblikket
      </div>
    {/if}
    <CreateLeagueCard>Lag en venneliga mens du venter!</CreateLeagueCard>
  </Shelf>
{/if}

<div class="guess-wrapper">
  {#if answeredOrClosed.length > 0 || questionsReadyForReveal.length > 0}
    <div class="spacer"></div>
    <h2 class="pill-h2">Dine gjett</h2>
    <Shelf
      numberOfDots={questionsReadyForReveal.length > 0
        ? answeredOrClosed.length + 1
        : answeredOrClosed.length}
    >
      {#if questionsReadyForReveal.length > 0}
        <GoldCard {revealDialog} readyForReveal={questionsReadyForReveal} />
      {/if}
      {#each answeredOrClosed as question (question.id)}
        <div
          in:receive={{ key: question.id }}
          out:send={{ key: question.id }}
          animate:flip={{ duration: cardAnimationDuration }}
        >
          <AnsweredQuestionCard {questionDialog} {question} />
        </div>
      {/each}
    </Shelf>
  {:else if answeredOrClosed.length === 0 && history.length === 0}
    <div class="spacer"></div>
    <h2 class="pill-h2">Dine gjett</h2>
    <Shelf>
      <div class="non-question-card card">
        Du har ikke gjettet på noen spørsmål enda.
      </div>
    </Shelf>
  {/if}
</div>
<h2 class="history pill-h2">Dine resultater</h2>
<div class="history-wrapper">
  <TodaysQuestionHistory questions={history} />
  <QuestionHistory />
</div>

<CoreDialog
  bind:this={questionDialog}
  id="answered-unanswered-dialog"
  on:close={closeQuestionDialog}
>
  {#if answeredOrClosed.find((q) => q.id === $currentQuestionId)}
    <AnsweredDialog {questionDialog} />
  {:else}
    <div class="center-children">
      <UnansweredDialog {questionDialog} />
    </div>
  {/if}
</CoreDialog>

<CoreDialog bind:this={revealDialog} id="reveal-dialog">
  {#if $currentRevealQuestionId != null}
    <div class="center-children">
      <GoldenDialog {revealDialog} readyForReveal={questionsReadyForReveal} />
    </div>
  {/if}
</CoreDialog>

<style>
  .guess-wrapper {
    background: var(--NRK-red-900, #450502);
  }

  @media (min-width: 62.5rem) {
    .guess-wrapper {
      margin: 0 -50vw;
      padding: 0 50vw;
    }
  }
  .spacer {
    padding-top: 1rem;
  }
  h2 {
    text-align: left;
    font-weight: 800;
    font-size: 1rem;
  }

  h2:focus {
    box-shadow: none;
  }

  .center-children {
    display: grid;
    grid-template-areas: 'stack';
  }

  .center-children > :global(*) {
    grid-area: stack;
  }
  .survey {
    height: 15.625rem;
    display: grid;
    place-items: center;
    color: var(--NRK-gray-200, #d0cdcd);
    font-weight: 700;
    font-size: 1.1rem;
    border-radius: 0.625rem;
    border: 0.125rem dashed var(--NRK-red-800, #7b0a02);
    background: conic-gradient(
        from 223deg at 93.82% 3.53%,
        rgba(0, 0, 0, 0) 0deg,
        rgba(0, 0, 0, 0.04) 360deg
      ),
      #1c0001;
    padding: 0.8rem;
  }
  .time {
    font-size: 2rem;
  }
  .survey-title {
    font-size: 1.2rem;
  }

  .survey-description {
    font-style: italic;
  }

  .survey {
    margin: 1rem auto;
    max-width: 28rem;
  }

  .primary-button {
    text-decoration: none;
  }

  .history {
    margin-top: 1rem;
  }

  .history-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    margin-top: 1rem;
    max-width: 100%;
  }
</style>
