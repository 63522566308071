<script lang="ts">
  import { emptyStateId } from '../api/clientState.js';
  import { state } from '../api/combinedClientState.js';
  import selectedTabStore, { tabs } from '../stores/selectedTabStore.js';
  import Poster from './Poster.svelte';
  import QuestionShelf from './QuestionShelf.svelte';
  import Rules from './Rules.svelte';
  import Scores from './Scores.svelte';
  import Loader from './common/Loader.svelte';
</script>

<u-tabs>
  <u-tablist>
    {#each tabs as tab (tab.name)}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <u-tab
        aria-selected={$selectedTabStore.name === tab.name}
        on:click={() => ($selectedTabStore = tab)}
        aria-controls={`tab-${tab.name}`}
      >
        <div class="center">
          <svelte:component this={tab.icon} />
        </div>
        {tab.name}
      </u-tab>
    {/each}
  </u-tablist>
  <!-- Need to check if it's the active tab or u-tab will mount all tabs at once -->
  <u-tabpanel class="first-tab" id={`tab-${tabs[0].name}`}>
    {#if $selectedTabStore.name === tabs[0].name}
      {#if $state?.id === emptyStateId || $state?.questions === undefined}
        <Loader description="Henter spørsmål" />
      {:else if $state.poster?.level === 'Info'}
        <Poster poster={$state.poster} />
      {:else}
        <QuestionShelf state={$state} />
      {/if}
    {/if}
  </u-tabpanel>
  <u-tabpanel id={`tab-${tabs[1].name}`}>
    {#if $selectedTabStore.name === tabs[1].name}
      <Scores />
    {/if}
  </u-tabpanel>
  <u-tabpanel id={`tab-${tabs[2].name}`}>
    {#if $selectedTabStore.name === tabs[2].name}
      <h2 class="pill-h2">Regler</h2>
      <Rules />
    {/if}
  </u-tabpanel>
</u-tabs>

<style>
  u-tablist {
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.0081rem;
    gap: var(--100, 4px);
    color: #fff2ee;
    margin: 2rem;
  }
  u-tabpanel:first-of-type {
    max-width: 62.5rem;
    margin: 0 auto;
  }

  u-tabpanel:not(:first-of-type) {
    max-width: var(--max-width);
    margin: 0 auto;
  }

  u-tab {
    width: 6.5rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #290103;
    gap: var(--200, 8px);
    padding: var(--200) var(--400);
    border-radius: var(--100);
    -webkit-tap-highlight-color: transparent;
    transition: transform 150ms ease-in;
  }
  u-tab:focus {
    box-shadow: none;
  }
  u-tab[aria-selected='true'] {
    color: #ffa90a;
  }

  h2 {
    margin-bottom: 1rem;
  }
</style>
