<script lang="ts">
  import { postFeedback, updateFeedback } from '../../api/feedback.js';
  import CoreDialog from '../core/CoreDialog.svelte';
  import EmojiFeedback from './EmojiFeedback.svelte';
  import { feedbackDialogStore } from './feedbackDialogStore.js';

  type Steps = 'SendFeedback' | 'Done';

  let feedbackDialog: CoreDialog;

  let step: Steps = 'SendFeedback';
  let emojivalue = -1;
  let comment = '';
  let isSending = false;
  let category = '';

  const handleNext = async () => {
    if (step === 'SendFeedback') {
      isSending = true;
      await postFeedback({ votesFeedback: emojivalue, category });
      if (comment.length) {
        await updateFeedback(comment);
      }
      emojivalue = -1;
      comment = '';
      category = '';
      step = 'Done';
      isSending = false;
    }
  };

  $: hasChangedValues = emojivalue !== -1 || comment !== '';
  $: if (feedbackDialog != null) {
    feedbackDialogStore.set(feedbackDialog);
  }
</script>

<CoreDialog
  bind:this={feedbackDialog}
  id="pin-feedback"
  on:close={() => (step = 'SendFeedback')}
>
  <div data-nosnippet class="content">
    {#if step === 'SendFeedback'}
      <EmojiFeedback bind:group={emojivalue} bind:comment />
      <div class="action-wrapper">
        <button
          class="close-button"
          on:click={() => {
            feedbackDialog.close();
          }}>Avbryt</button
        >
        <button
          class="primary-button"
          on:click={handleNext}
          disabled={isSending || !hasChangedValues}>Send</button
        >
      </div>
    {:else}
      <h2>Takk for din tilbakemelding!</h2>
      <div class="survey">
        Har du noen minutter til overs? Vi vil gjerne bli enda bedre, og hadde
        satt stor pris på om du vil fylle ut en liten undersøkelse.
        <a
          class="primary-button"
          href="https://surveys.enalyzer.com?pid=fubag8c2"
          target="_blank">Svar på undersøkelsen</a
        >
      </div>
    {/if}
  </div>
</CoreDialog>

<style>
  .content {
    display: flex;
    height: auto;
    min-height: 15.625rem;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    margin: 0 auto 0;
    max-width: 26rem;
    min-width: 17rem;
    color: white;
    width: 100%;
    background-color: #000;
    padding: 2rem;
    padding-bottom: 2rem;
  }

  .action-wrapper {
    display: flex;
    justify-content: center;
  }

  .action-wrapper :global(button) {
    margin: 0.25rem;
    max-width: 9rem;
    min-width: 9rem;
  }

  button {
    height: 3rem;
    padding: 0 1rem;
    border: 0.0625rem solid white;
  }

  .close-button {
    background-color: black;
    color: white;
  }

  .primary-button {
    padding: 1rem;
    display: block;
    display: flex;
    margin: 1rem auto;
    text-decoration: none;
  }
  a {
    font-weight: 800;
  }

  .primary-button {
    margin: 2rem 0 0 0;
  }
</style>
