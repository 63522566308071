<script lang="ts">
  import * as Sentry from '@sentry/svelte';
  import { trackEvent } from '../../util/snowplow';
  import {
    getItem,
    openedQuestionKey,
    removeItem,
    setItem,
  } from '../../util/storage.js';
  import { loginModel } from '../Login/loginModel';
  import { loginClient } from '../Login/loginSessionStore.js';
  import CoreDialog from '../core/CoreDialog.svelte';
  import { loginDialogStore } from './loginDialogStore.js';

  let loginDialog: CoreDialog;
  $: if (loginDialog != null) {
    loginDialogStore.set(loginDialog);
  }

  const login = async () => {
    trackEvent({ action: 'click:login-login-dialog' });
    try {
      loginModel.login();
    } catch (error) {
      Sentry.setTag('offgrid', 'login');
      Sentry.captureException(error);
      trackEvent({ action: 'error:login.offgrid' });
      const lastForceRefresh = getItem<string>('local', 'lastForceRefresh');
      const now = Date.now();

      if (!lastForceRefresh || now - parseInt(lastForceRefresh, 10) > 10_000) {
        await loginClient.forceRefresh();
        setItem('local', 'lastForceRefresh', now.toString());
      }
    }
  };
</script>

<CoreDialog
  bind:this={loginDialog}
  id="login-dialog"
  on:close={() => {
    removeItem('session', openedQuestionKey);
  }}
>
  <div class="dialog-content dark-card">
    <div class="header">Logg på leken!</div>

    <img src="Result-Trophy.svg" alt="Gull og gråfarget pokal med to håndtak" />
    <div class="text">
      For å kunne gjette, se Norgesligaen, eller opprette din egen venneliga må
      du logge på NRK.
    </div>
    <div class="button-wrapper">
      <button class="primary-button" on:click={login}> Logg på NRK</button>
      <button class="close-button" on:click={loginDialog.close}>Ikke nå</button>
    </div>
  </div>
</CoreDialog>

<style>
  .dialog-content {
    justify-content: space-between;
    height: 18.75rem;
  }

  .header {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }
  img {
    height: 7.5rem;
  }

  .text {
    text-align: left;
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1rem;
    font-style: normal;
    font-weight: 560;
    line-height: normal;
  }

  .button-wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
  .close-button {
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1rem;
    width: 100%;
    font-weight: 700;
    display: flex;
    height: var(--700, 3rem);
    padding: 0.75rem;
    justify-content: center;
    border-radius: 0.125rem;
    border: 0.0625rem solid var(--NRK-gray-100, #ebe7e6);
  }
</style>
