import { writable } from 'svelte/store';
import Cards from '../components/Icons/Cards.svelte';
import Info from '../components/Icons/Info.svelte';
import League from '../components/Icons/League.svelte';
import { getItem, setItem } from '../util/storage.js';

const localStorageKey = 'selected-tabs-v2';

const selectedTabStore = () => {
  const { subscribe, set: setStore } = writable<Tab>(getInitialSelectedTab());

  const set = (value: Tab) => {
    setStore(value);
    setItem('local', localStorageKey, value);
  };

  return {
    subscribe,
    set,
  };
};

export const tabs = [
  { name: 'Spill', icon: Cards },
  { name: 'Liga', icon: League },
  { name: 'Regler', icon: Info },
] as const;

type Tab = (typeof tabs)[number];

const getInitialSelectedTab = () => {
  const userSelectedTab = getItem<Tab>('local', localStorageKey);
  if (userSelectedTab != null) {
    return userSelectedTab;
  }
  return tabs[0];
};

export default selectedTabStore();
