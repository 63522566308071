<script lang="ts">
  import type { ScoreBoard } from '@pin/schemas/clientSchema';
  import Crown from '../Icons/Crown.svelte';
  import Medal from '../Icons/Medal.svelte';
  import PaperHat from '../Icons/PaperHat.svelte';
  import TrophyDetailed from '../Icons/TrophyDetailed.svelte';

  export let scoreboard: Omit<ScoreBoard['scores'][number], 'numOfRanks'>[];

  export let type:
    | 'norway-today'
    | 'norway-total'
    | 'league-today'
    | 'league-total' = 'norway-total';

  $: topthree = scoreboard.slice(0, 3);
  $: scoresFromFourth = scoreboard.slice(3);
  $: scoresList =
    scoresFromFourth.length === 0 && topthree.length < 3
      ? scoreboard
      : scoresFromFourth;
</script>

<div class="icon">
  {#if type === 'norway-today'}
    <Medal />
  {:else if type === 'league-today'}
    <PaperHat />
  {:else if type === 'league-total'}
    <Crown />
  {:else}
    <TrophyDetailed />
  {/if}
</div>

{#if topthree.length === 3}
  <div class="podium">
    {#each topthree as user, i}
      <div
        class="spot"
        class:first={i === 0}
        class:second={i === 1}
        class:third={i === 2}
        aria-label={`${user.rank}. ${user.name} - ${user.score} poeng`}
        role="img"
      >
        <div class="podium-rank" aria-hidden="true">{i + 1}</div>
        <div
          class="podium-name"
          aria-hidden="true"
          class:currentUser={user.isCurrentUser}
        >
          {user.name}
        </div>
        <div class="podium-score" aria-hidden="true">{user.score}p</div>
      </div>
    {/each}
  </div>
{/if}
{#if scoresList.length > 0}
  <ol start={scoresList[4]?.rank}>
    {#each scoresList as user}
      <li
        aria-label={`${user.rank}. ${user.name} - ${user.score} poeng`}
        class:currentUser={user.isCurrentUser}
      >
        <span class="rank">
          <span aria-hidden="true">
            {user.rank}.
          </span>
        </span>

        <span class="name-and-points-wrapper" aria-hidden="true">
          <span class="name">{user.name} </span>

          <span class="score" aria-hidden="true">{user.score}</span>
        </span>
      </li>
    {/each}
  </ol>
{/if}

<style>
  * {
    font-weight: 700;
    font-size: 0.9rem;
  }
  .icon {
    margin: 2.5rem 0 2rem;
    height: 2.5rem;
  }
  ol {
    margin-top: 4.5rem;
    padding: 0;
    list-style: none;
    width: 100%;
  }
  li {
    font-variant-numeric: tabular-nums;
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 0.625rem;
    grid-template-rows: 1.5625rem;
    padding: 0.5rem 1rem;
    align-items: center;
  }

  li:nth-child(odd) {
    background: rgba(69, 5, 2, 0.67);
  }

  li:nth-child(even) {
    background: #230200;
  }

  .rank {
    text-align: left;
  }

  .name-and-points-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .name {
    text-align: left;
    position: relative;
    max-width: 12.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 4;
  }

  li.currentUser {
    background: #855000 !important;
  }
  div.currentUser {
    color: orange;
  }

  /* PODIUM  */
  .podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 9.375rem;
    margin-bottom: 3rem;
  }

  .podium .spot {
    width: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin: 0.0313rem;
  }

  .spot.first {
    order: 2;
    z-index: 3;
    height: 7.5rem;
    background: var(--NRK-cool-orange-500, #ffa90a);
    box-shadow:
      0px 100px 80px 0px rgba(0, 0, 0, 0.83),
      0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.6),
      0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.49),
      0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.41),
      0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.34),
      0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.23);
  }

  .spot.second {
    order: 1;
    z-index: 2;
    height: 5.625rem;
    background: var(--NRK-warm-orange-600, #f56a00);
  }

  .spot.third {
    order: 3;
    height: 4.375rem;
    background: var(--NRK-warm-orange-800, #7c3500);
  }

  .podium-name {
    position: absolute;
    top: -1.5625rem;
    max-width: 4.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .first > .podium-name {
    max-width: 11.25rem;
  }

  .podium-rank {
    margin-bottom: 0.5rem;
    color: var(--NRK-gray-950, #131317);
    text-shadow: 0rem 0.0625rem 0rem hsla(18, 73%, 40%, 1);
    font-size: 1.8rem;
    line-height: normal;
  }

  .podium-score {
    position: absolute;
    bottom: -1.875rem;
  }
</style>
