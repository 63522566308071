import './util/polyfill.js';

import '@u-elements/u-tabs';
import './styles/global.css';
import { initializeVersionCheck } from './util/versionCheck.js';
import { initializeSnowPlowTracker } from './util/snowplow.js';

import { initSentry } from './util/sentry.js';
import './util/vhcalculation.js';
import App from './App.svelte';
import { mount } from 'svelte';

const initializeApp = async () => {
  initializeVersionCheck();
  initializeSnowPlowTracker();
  initSentry();

  return mount(App, {
    target: document.getElementById('appentry')!,
  });
};

const app = initializeApp();
export default app;
