<script lang="ts">
  import Feedback from './Feedback.svelte';
  import { feedbackDialogStore } from './feedbackDialogStore.js';
</script>

<button
  class="button"
  on:click={() => {
    $feedbackDialogStore?.show();
  }}
>
  Gi oss din tilbakemelding
</button>
<Feedback />

<style>
  :global(.after-background) .button {
    color: #000;
  }
  .button {
    max-width: 13rem;
    display: block;
    margin: 2rem auto;
    padding: 0.6rem 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.1em;
    text-align: center;
    transition: all 0.2s ease-in-out;
    border: none;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
  }
</style>
