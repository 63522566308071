<script lang="ts">
  export let id: string;
  export let hidden = true;
  export let closeOnOutsideClick = true;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import CoreToggle from '@nrk/core-toggle'; // Using NPM
  if (window.customElements.get('nrk-toggle') === undefined) {
    window.customElements.define('nrk-toggle', CoreToggle);
  }
</script>

<nrk-toggle {id} data-popup {hidden} popup={closeOnOutsideClick}>
  <slot />
</nrk-toggle>

<style>
  nrk-toggle:not([hidden]) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
</style>
