<script lang="ts">
  import { onDestroy, onMount } from 'svelte';

  export let numberOfDots: number = 1;

  let scroller: HTMLDivElement;
  let content: HTMLDivElement;

  let dotsContainer: HTMLDivElement;
  let dotsInner: HTMLDivElement;

  let progressDots: boolean[] = [];

  let dotsOffset = 0;

  const initializeDots = () => {
    progressDots = Array.from({ length: numberOfDots }, () => false);
  };

  const refreshDots = () => {
    initializeDots();
    handleScroll();
  };

  const handleScroll = () => {
    if (!scroller) return;

    const { scrollLeft, scrollWidth, clientWidth } = scroller;
    const scrollFraction =
      scrollWidth === clientWidth
        ? 0
        : scrollLeft / (scrollWidth - clientWidth);

    progressDots = progressDots.map((_, i) => {
      const child = content.children[i] as HTMLElement;
      if (!child) return false;

      const childRect = child.getBoundingClientRect();
      const scrollerRect = scroller.getBoundingClientRect();

      const isVisible =
        childRect.right - childRect.width / 2 > scrollerRect.left &&
        childRect.left + childRect.width / 2 < scrollerRect.right;

      return isVisible;
    });

    const dotsInnerWidth = dotsInner?.scrollWidth ?? 0;
    const dotsContainerWidth = dotsContainer?.clientWidth ?? 0;

    if (dotsInnerWidth > dotsContainerWidth) {
      dotsOffset = scrollFraction * (dotsInnerWidth - dotsContainerWidth);
    } else {
      dotsOffset = 0;
    }
  };

  $: numberOfDots, refreshDots();

  onMount(() => {
    scroller.addEventListener('scroll', handleScroll);
    refreshDots();
  });

  onDestroy(() => {
    scroller.removeEventListener('scroll', handleScroll);
  });
</script>

<div bind:this={scroller} class="scroller">
  <div bind:this={content} class="content">
    <slot></slot>
  </div>
</div>

<div bind:this={dotsContainer} class="dots-container">
  <div
    bind:this={dotsInner}
    class="dots-inner"
    style="transform: translateX({-dotsOffset}px);"
  >
    {#each progressDots as visible}
      <div class="dot" class:visible></div>
    {/each}
  </div>
</div>

<style>
  .scroller {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    height: 17rem;
    align-items: center;
    max-width: 62.5rem;
    margin: 0 auto;
  }
  .scroller::-webkit-scrollbar {
    display: none;
  }

  .content {
    display: flex;
    gap: 0.6rem;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .dots-container {
    position: relative;
    overflow: hidden;
    max-width: 62.5rem;
    margin: 0 1rem;
    padding: 1rem;
  }
  .dots-inner {
    display: flex;
    gap: 0.25rem;
    transition: transform 0.2s ease-out;
    flex-shrink: 0;
    justify-content: center;
  }
  .dot {
    min-width: 0.625rem;
    height: 0.625rem;
    background-color: #ffc7bd;
    border-radius: 50%;
    margin: 0 0.1rem;
    transition: background-color 0.25s ease;
    filter: drop-shadow(0 0 3px #450502);
  }
  .dot.visible {
    background-color: #f34531;
  }
</style>
