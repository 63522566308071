<script lang="ts">
  import type { Question } from '@pin/schemas/clientSchema';
  import { isClosed } from '../../util/questionState.js';
  import { currentQuestionId } from '../../util/questionStateStore.js';
  import { trackEvent } from '../../util/snowplow.js';
  import Pill from '../Pill.svelte';
  import type CoreDialog from '../core/CoreDialog.svelte';
  export let questionDialog: CoreDialog;
  export let question: Question;

  $: closed = isClosed(question);

  const getColor = () => {
    if (question.score === 500) return 'gold';
    if (question.score === 250) return 'silver';
    return 'bronze';
  };
</script>

<button
  class="wrapper card"
  on:click={() => {
    trackEvent({ action: 'click:answered-card' });
    currentQuestionId.set(question.id);
    questionDialog.show();
  }}
>
  <Pill {question} dark={false} />
  <div class="question-text">{question.text}</div>

  <div class="user-guess">
    {#if question.answer}
      <div class="answer-header">{closed ? 'Du gjettet:' : 'Du gjetter:'}</div>
    {/if}
    {#if question.answer}
      {#if question.type === 'MultipleChoice'}
        <div class="answer">
          {question.answer.alternative.text}
        </div>
      {:else}
        <div class="answer">{question.answer.value}</div>
      {/if}
    {/if}
  </div>
  {#if !closed}
    <div class="change-button secondary-button">Endre</div>
  {:else}
    <div class="spacer"></div>
  {/if}
  <div class={getColor()}></div>
</button>

<style>
  .wrapper {
    display: grid;
    justify-items: center;
    overflow: hidden;
    grid-template-rows: 0.875rem 5rem 5rem 2.5rem 0.625rem;
  }
  .wrapper {
    color: var(--NRK-gray-100, #ebe7e6);
    background: var(--NRK-gray-950, #131317);
    font-weight: 560;
    padding: var(--400, 1rem);
  }

  @supports (conic-gradient(black, white)) {
    .wrapper {
      background: conic-gradient(
          from 223deg at 93.82% 3.53%,
          rgba(0, 0, 0, 0) 0deg,
          rgba(0, 0, 0, 0.02) 360deg
        ),
        var(--NRK-gray-950, #131317);
    }
  }

  .question-text {
    max-width: 8rem;
    margin-top: 1rem;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 3;
    text-align: left;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .user-guess {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
  }
  .answer-header {
    font-weight: 890;
  }
  .answer {
    font-size: 1rem;
    font-weight: 700;
  }

  .spacer {
    height: 1.688rem;
  }
  .change-button {
    background: var(--NRK-gray-100, #ebe7e6);
    color: var(--NRK-gray-950, #131317);
    padding: 0.25rem 0;
    font-size: 1rem;
    font-weight: 700;
    font-stretch: expanded;
    height: 1.875rem;
  }

  button:focus .change-button {
    box-shadow: var(--focus-box);
  }

  .gold,
  .silver,
  .bronze {
    width: 140%;
    height: 0.625rem;
  }
</style>
