import type { Question } from '@pin/schemas/clientSchema';
import { writable } from 'svelte/store';
import { submitReveal } from '../api/submitReveal.js';

export const currentRevealQuestionId = writable<string | null>();

export const revealQuestion = async (
  readyForReveal: Question[],
  nextQuestionIndex: number
) => {
  const questionToReveal = readyForReveal[nextQuestionIndex];
  await submitReveal(questionToReveal.id);
  currentRevealQuestionId.set(questionToReveal.id);
};
