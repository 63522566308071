<script lang="ts">
  import { nrkSpinner } from '@nrk/core-icons';
  import { captureException } from '@sentry/svelte';
  import { onMount } from 'svelte';
  import {
    addMemberToLeague,
    fetchLeaguesSubject,
    getLeague,
  } from '../../api/fetchleague.js';
  import { delay } from '../../util/delay.js';
  import { removeQueryParam } from '../../util/queryParam';
  import { impression, trackEvent } from '../../util/snowplow.js';
  import { getItem, removeItem, setItem } from '../../util/storage';
  import CoreDialog from '../core/CoreDialog.svelte';
  import { loginModel } from '../Login/loginModel';
  import { loginSessionStore } from '../Login/loginSessionStore';

  let dialog: CoreDialog;
  let joinLeague: Awaited<ReturnType<typeof getLeague>> | null;
  let submitting = false;
  let error: string | false = false;
  let success = false;
  const errorMessages = {
    generic: 'Prøv igjen',
    maxUserLeagues: 'Maks antall ligaer nådd',
    maxLeagueMembers: 'Ligaen har nådd maks antall medlemmer',
  };

  const getLeagueId = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('leagueId');
  };

  onMount(async () => {
    const leagueId = getLeagueId();
    if (leagueId != null) {
      joinLeague = await getLeague(leagueId);
    } else {
      joinLeague = getItem(
        'session',
        joinLeagueSessionStorageKey
      ) as typeof joinLeague;
    }
    // todo: check if user is in league already
  });

  const joinLeagueSessionStorageKey = `join-league`;

  const handleClose = () => {
    removeItem('session', joinLeagueSessionStorageKey);
    removeQueryParam('leagueId');
  };

  const handleLogin = () => {
    setItem('session', joinLeagueSessionStorageKey, joinLeague);
    removeQueryParam('leagueId');
    loginModel.login();
  };

  const handleJoinLeague = async (leagueId: string) => {
    error = false;
    try {
      trackEvent({ action: 'click:join-league' });
      submitting = true;
      const result = await addMemberToLeague(leagueId);
      if (!(result instanceof Response)) {
        const { message } = result;
        if (message.includes('users')) {
          return await showErrorThenClose(errorMessages.maxUserLeagues);
        } else if (message.includes('members')) {
          return await showErrorThenClose(errorMessages.maxLeagueMembers);
        } else {
          error = errorMessages.generic;
          return;
        }
      }
      return await showSuccessThenClose();
    } catch (e) {
      captureException(e);
      error = errorMessages.generic;
    } finally {
      submitting = false;
    }
  };

  const showErrorThenClose = async (message: string) => {
    error = message;
    submitting = false;
    await delay(2_001);
    error = false;
    fetchLeaguesSubject.next({ cacheRefresh: true });
    dialog.close();
  };

  const showSuccessThenClose = async () => {
    success = true;
    submitting = false;
    await delay(1_001);
    success = false;
    fetchLeaguesSubject.next({ cacheRefresh: true });
    dialog.close();
  };
</script>

{#if joinLeague != null}
  <CoreDialog
    bind:this={dialog}
    id="join-league"
    on:close={handleClose}
    visible={true}
  >
    <div class="container" use:impression={'join-league'}>
      <h1>{`Bli med i ligaen “${joinLeague.name}”`}</h1>
      <img
        src="Result-Trophy.svg"
        alt="Gull og gråfarget pokal med to håndtak"
      />
      {#if $loginSessionStore?.isLoggedIn}
        <p>
          Du har blitt invitert til å konkurrere i en venneliga i Gjettekonken
          til NM på ski.
        </p>
        <p>Hvem klarer å samle flest poeng og tar seieren i dag?</p>
        <button
          class:success
          class:error
          on:click={() => (joinLeague ? handleJoinLeague(joinLeague.id) : null)}
          disabled={submitting || success}
        >
          {#if error}
            <span aria-live="assertive">
              {error}
            </span>
          {:else if success}
            <span aria-live="assertive">
              Du har blitt med i {joinLeague.name}
            </span>
          {:else}
            Bli med i ligaen {#if submitting}
              <span class="inline-spinner">
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html nrkSpinner}
              </span>
            {/if}
          {/if}
        </button>
        <button class="close-button" on:click={dialog.close}>Nei takk</button>
      {:else}
        <p>
          Du har blitt invitert til konkurrere i en venneliga i Gjettekonken
        </p>
        <p>Logg på konkuransen</p>
        <button on:click={handleLogin}>Logg på</button>
      {/if}
    </div>
  </CoreDialog>
{/if}

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    min-width: 20.625rem;
    max-width: var(--max-width);
    background: black;
    color: white;
  }

  .close-button {
    background-color: black;
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    height: var(--700, 3rem);
    padding: 0.75rem;
    justify-content: center;
    border-radius: 0.125rem;
    border: 0.0625rem solid var(--NRK-gray-100, #ebe7e6);
  }

  h1 {
    text-align: center;
    max-width: 12rem;
    margin-inline: auto;
    overflow-wrap: anywhere;
  }

  h1 {
    font-size: 1.375rem;
  }

  .success {
    background: var(--NRK-cool-green-400, #5dc479);
    color: white;
  }

  .error {
    background: var(--NRK-cool-orange-400, #feb937);
    color: var(--NRK-black, #000);
  }

  img {
    height: 7rem;
  }

  button {
    background: var(--NRK-gray-100, #ebe7e6);
    color: black;
    font-weight: 800;
    min-width: 14rem;
    padding: 0.7rem 2rem;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
</style>
