import {
  from,
  lastValueFrom,
  mergeMap,
  switchMap,
  throwError,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { getFreshSession$ } from '../components/Login/loginSessionStore';
import { checkNoContentSuccess, retryFetch } from '../util/rxjs.http.js';
import { fetchAnswerSubject } from './answers.js';
import { getClientId } from './clientId.js';

export const submitReveal = async (questionId: string) => {
  await lastValueFrom(
    from(getFreshSession$).pipe(
      switchMap((session) =>
        session?.accessToken != null
          ? fromFetch(
              `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/answers/${questionId}/reveal`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${session.accessToken}`,
                },
              }
            ).pipe(checkNoContentSuccess(), timeout(6_000), retryFetch(4))
          : // If accessToken is null there might be some issues with innlogging
            // so we wait 5 seconds to not give the retry option to the user too early.
            timer(5_000).pipe(
              mergeMap(() => throwError(() => new Error('No token')))
            )
      )
    )
  );
  fetchAnswerSubject.next({ cacheRefresh: true });
};
