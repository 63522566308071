<script lang="ts">
  import { nrkSomeEmail } from '@nrk/core-icons';
  import { clientState$ } from '../api/clientState.js';
  import SvgClock from './SVGClock.svelte';
  import Shelf from './Shelf.svelte';

  export let noMoreQuestions = false;

  let hour = '16';
  let minute = '00';

  $: text =
    $clientState$.info === '' || $clientState$.info === undefined
      ? 'Ny konkurranse kommer!'
      : $clientState$.info;

  const formatText = (input: string) => {
    if (!input) return '';
    const timeRegex = /(\d{1,2}):(\d{1,2})/;
    const match = input.match(timeRegex);

    if (match) {
      hour = match[1];
      minute = match[2];
    }

    const withoutTime = input.replace(timeRegex, '').trim();
    return withoutTime;
  };
</script>

<Shelf numberOfDots={3}>
  {#if noMoreQuestions}
    <div class="non-question-card card">
      {formatText(text)}
      <span>
        <SvgClock {hour} {minute} />
      </span>
      {#if $clientState$?.info}
        <div class="time">
          {hour}:{minute}
        </div>
      {/if}
    </div>
  {/if}

  {#if !noMoreQuestions}
    <div class="non-question-card card">
      Takk for nå!<br /> Vi sees til SKI-VM!
      <!-- <span>
        <SvgClock hour="09" minute="30" />
      </span>
      <span class="time"> kl. 09:30 </span> -->
    </div>
  {/if}
  {#if noMoreQuestions}
    <div class="non-question-card card">
      Svar på undersøkelsen og hjelp oss å bli enda bedre
      <a
        class="primary-button"
        href="https://surveys.enalyzer.com?pid=fubag8c2"
        target="_blank">Gå til skjema</a
      >
    </div>
  {/if}

  <div class="non-question-card card">
    Vinneren kontaktes på e-post <span class="icon">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkSomeEmail}
    </span>
  </div>
</Shelf>

<style>
  .icon {
    display: grid;
    place-content: center;
    font-size: 2rem;
    height: 1.25rem;
    color: #ffa90a;
  }

  .time {
    font-size: 1.8rem;
  }

  .primary-button {
    font-size: 0.9rem;
    text-decoration: none;
    padding: unset;
    height: 1.875rem;
    min-height: unset;
  }
</style>
