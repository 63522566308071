import {
  distinctUntilChanged,
  fromEvent,
  map,
  shareReplay,
  startWith,
} from 'rxjs';

/**
 * Observable of document visibility, updates when visibility changes.
 */
export const isVisible$ = fromEvent(document, 'visibilitychange').pipe(
  map(() => document.visibilityState === 'visible'),
  startWith(true),
  distinctUntilChanged(),
  shareReplay({ refCount: true, bufferSize: 1 })
);
