<script lang="ts">
  import { clientState$ } from '../../../api/clientState';
  import { scoreboard$ } from '../../../api/scoreboard';
  import Loader from '../../common/Loader.svelte';
  import { loginSessionStore } from '../../Login/loginSessionStore';
  import NorwayScoreboard from './NorwayScoreboard.svelte';
  import ScoreboardLoadError from './ScoreboardLoadError.svelte';
  $: isAfter = $clientState$.state === 'After';
</script>

{#if $loginSessionStore?.isLoggedIn === false}
  <NorwayScoreboard />
{:else if $scoreboard$ == null && $clientState$.isNorwayLeagueEnabled}
  <div class="loading-container">
    <Loader description="Henter Norgesliga" />
  </div>
{:else if ($scoreboard$ && !$scoreboard$.isNorwayLeagueEnabled) || (!$scoreboard$ && !$clientState$.isNorwayLeagueEnabled)}
  <ScoreboardLoadError />
{:else}
  {#if !isAfter}
    <h2 class="pill-h2">Offentlige ligaer</h2>
  {/if}
  {#if $scoreboard$ && $scoreboard$.scores.length > 0}
    <NorwayScoreboard scoreboard={$scoreboard$} />
  {:else}
    <NorwayScoreboard showLoginButton={false} />
  {/if}
{/if}

<style>
  .loading-container {
    margin-top: 1rem;
  }
</style>
