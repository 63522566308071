<script lang="ts">
  import { nrkSpinner } from '@nrk/core-icons';
  import type { AnswerSubmit, ValueQuestion } from '@pin/schemas/clientSchema';
  import * as Sentry from '@sentry/svelte';
  import { onDestroy, onMount } from 'svelte';
  import { fetchAnswerSubject } from '../../api/answers.js';
  import { submitAnswer } from '../../api/submitAnswer.js';
  import { delay } from '../../util/delay.js';
  import { isClosed } from '../../util/questionState.js';
  import { trackEvent } from '../../util/snowplow.js';
  import { openedQuestionKey, setItem } from '../../util/storage.js';
  import { loginModel } from '../Login/loginModel';
  import { loginSessionStore } from '../Login/loginSessionStore';
  import type CoreDialog from '../core/CoreDialog.svelte';

  export let question: ValueQuestion;
  export let questionDialog: CoreDialog;
  export let answered = false;
  const { minValue, maxValue } = question;

  let value = question.answer?.value ?? minValue;
  const numbers = Array.from(
    { length: maxValue - minValue + 1 },
    (_, index) => minValue + index
  );

  let containerRef: HTMLDivElement | null;
  let questiontext: HTMLDivElement | null;
  let numberButtonRef = new Array<HTMLButtonElement>(numbers.length);

  const getNumberCenter = (index: number) => {
    if (containerRef == null) {
      return null;
    }
    // :before og :after elementene som skaper padding er 50% av bredden til containeren
    const padding = containerRef.offsetWidth;
    const numberWidth = (containerRef.scrollWidth - padding) / numbers.length;
    const paddingLeft = padding / 2;
    return numberButtonRef[index].offsetLeft - paddingLeft + numberWidth / 2;
  };

  const scrollSelectedToCenter = (
    current: number,
    behavior: 'smooth' | 'auto' = 'smooth'
  ) => {
    const i = numbers.indexOf(current);
    const pos = getNumberCenter(i);
    if (pos == null) {
      return;
    }
    containerRef?.scrollTo({
      left: pos,
      behavior,
    });
  };
  const handleScroll = (e: Event) => {
    const numberList = e.target as HTMLDivElement;
    const scrollWidth = numberList.scrollWidth - numberList.offsetWidth;

    // trekker fra 0.5 siden scroll er sentrert på hvert tall
    const currentPos =
      minValue +
      Math.abs(
        Math.round((numberList.scrollLeft / scrollWidth) * numbers.length - 0.5)
      );
    if (currentPos <= maxValue && currentPos >= minValue) {
      value = currentPos;
    }
  };

  onMount(() => {
    if (questiontext != null) {
      questiontext.focus();
    }
    scrollSelectedToCenter(value, 'auto');
    containerRef?.addEventListener('scroll', handleScroll);
  });

  onDestroy(() => {
    containerRef?.removeEventListener('scroll', handleScroll);
  });

  let isSubmitting = false;
  let error = false;
  let success = false;
  $: selected = value === question.answer?.value;

  const handleSubmit = async (value: number) => {
    if (
      $loginSessionStore == undefined ||
      $loginSessionStore.accessToken == undefined
    ) {
      trackEvent({ action: 'click:value-guess-redirected-to-login' });
      setItem('session', openedQuestionKey, question.id);
      loginModel.login();
      return;
    }
    isSubmitting = true;
    error = false;

    try {
      trackEvent({
        action: `click:submit-answer-value-guess-${answered ? 'answered' : 'unanswered'}`,
      });

      const payload: AnswerSubmit = {
        questionId: question.id,
        value: value.toString(),
      };
      await submitAnswer(payload);
      trackEvent({ action: 'interaction' });
      await showSuccessThenClose();
    } catch (e: any) {
      error = true;
      trackEvent({ action: 'error:submit-answer-value-guess' });
      Sentry.captureException(e);
    } finally {
      isSubmitting = false;
    }
  };

  const showSuccessThenClose = async () => {
    success = true;
    isSubmitting = false;
    await delay(1_001);
    success = false;
    fetchAnswerSubject.next({ cacheRefresh: true });
    questionDialog.close({ openingElementWillBeRemoved: answered === false });
  };
  $: closed = isClosed(question);
</script>

<div class="container">
  <div class="question-text" bind:this={questiontext} tabindex="-1">
    {question.text}
  </div>
  {#if answered && closed}
    {#if question.answer?.value != null}
      <div class="answer-wrapper">
        <div class="answer-header">Du gjettet:</div>
        <div class="answer">{question.answer?.value}</div>
      </div>
    {/if}
  {/if}
  {#if !closed}
    {#if answered}
      <div class="answer-wrapper" aria-hidden="true">
        <div class="answer-header">Du gjetter:</div>
      </div>
    {/if}
    <div class="inputwrapper">
      <input
        id="rangeinput"
        type="range"
        min={minValue}
        max={maxValue}
        step={1}
        aria-valuetext={answered && question.answer?.value === value
          ? `Ditt gjett er ${value}`
          : `Gjett ${value}`}
        {value}
        on:change={(e) => {
          value = Number(e.currentTarget.value);
          scrollSelectedToCenter(value);
        }}
      />
      <div class="number-container" bind:this={containerRef}>
        {#each numbers as number, i (i)}
          <button
            tabindex="-1"
            aria-hidden="true"
            class="number-button"
            bind:this={numberButtonRef[i]}
            on:click|preventDefault={() => {
              value = number;
              scrollSelectedToCenter(value);
            }}
            class:currentAnswer={answered && question.answer?.value === number}
            class:currentvalue={value === number}
            class:oneoffcurrentvalue={value - 1 === number ||
              value + 1 === number}
            class:twooffcurrentvalue={value - 2 === number ||
              value + 2 === number}
          >
            <span>
              {number}
            </span>
          </button>
        {/each}
      </div>
    </div>
  {/if}
</div>
{#if !closed}
  <button
    class="secondary-button"
    class:selected
    class:answered
    class:success
    class:error
    data-test-id={`submit-answer-0`}
    on:click|preventDefault={() => handleSubmit(value)}
    disabled={isSubmitting || success || selected}
    aria-busy={isSubmitting}
    title="Trykk for å gjette {value}"
  >
    {#if success}
      <span aria-live="assertive"> Svar registrert </span>
    {:else if error}
      <span aria-live="assertive"> Prøv igjen </span>
    {:else}
      {answered ? 'Endre' : 'Gjett'}
    {/if}
    {#if isSubmitting}
      <span class="inline-spinner">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkSpinner}
      </span>
    {/if}
  </button>
{/if}

<style>
  .answer-wrapper {
    margin: 1rem auto;
  }

  .question-text {
    text-align: left;
  }

  .question-text:focus {
    box-shadow: none;
  }

  .answer-header {
    font-size: 1.313rem;
    font-weight: 890;
  }

  .answer {
    font-size: 3.75rem;
    font-weight: 800;
    color: var(--NRK-cool-orange-500, #ffa90a);
  }

  .inputwrapper {
    position: relative;
  }

  .secondary-button {
    font-size: 1rem;
    font-stretch: expanded;
  }

  .success {
    background: var(--NRK-cool-green-400, #5dc479);
    color: white;
  }

  .error {
    background: var(--NRK-cool-orange-400, #feb937);
    color: var(--NRK-black, #000);
  }

  /* range input  */
  input[type='range'] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  .number-button,
  input[type='range']:focus {
    box-shadow: none;
  }

  .number-container {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;
    padding: 1rem 0;
  }

  input[type='range']:focus + .number-container {
    box-shadow: var(--focus-box);
  }

  .number-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  .number-container:before,
  .number-container:after {
    display: inline-block;
    width: 50%;
    height: 0.0625rem;
    content: '';
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .number-button {
    width: 3.0625rem;
    transition: all 0.3 ease-in;
    -webkit-scroll-snap-align: center;
    scroll-snap-align: center;
    transform: translateZ(0);
    padding: 1rem;
  }

  button span {
    display: inline-block;
    opacity: 0.5;
    transform: translateZ(0);
  }
  .currentvalue span {
    transform: scale(2);
    opacity: 1;
  }

  .currentAnswer {
    color: var(--NRK-cool-orange-500, #ffa90a);
  }

  .secondary-button {
    width: 70%;
  }
  .oneoffcurrentvalue span {
    transform: scale(1.5);
    opacity: 0.7;
  }
</style>
