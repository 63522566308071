import {
  getRedirectLoginClient,
  type LoginSession,
} from '@nrk/innlogging-web/redirect-login-client';
import type { UserInfo } from '@pin/schemas/clientSchema';
import * as Sentry from '@sentry/svelte';
import {
  catchError,
  distinctUntilChanged,
  from,
  lastValueFrom,
  of,
  shareReplay,
  switchMap,
  timeout,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { writable } from 'svelte/store';
import { checkNoContentSuccess, retryFetch } from '../../util/rxjs.http.js';
import { setUser, trackEvent } from '../../util/snowplow.js';

export const loginClient = getRedirectLoginClient({});

export const loginSessionStore = writable<LoginSession | null>();

const fetchInitialSession = async () => {
  const session = await loginClient.getSession();
  if (session.isLoggedIn && !!session.user) {
    setUser(session.user?.sub);
  }

  if (session.isLoggedIn && (session.isOffgrid || session.isExpired)) {
    try {
      const refreshedSession = await loginClient.forceRefresh();
      loginSessionStore.set(refreshedSession);
      // We track how many users end up in this state.
      trackEvent({
        action: 'error:session-expired',
      });
      return refreshedSession;
    } catch (error: unknown) {
      Sentry.captureException(error);
      trackEvent({
        action: 'error:session-failure-refresh-init',
      });
      return session;
    }
  }
  loginSessionStore.set(session);
  loginClient.initOpSession();
  loginClient.addEventListener('update', onSessionUpdate);
  return session;
};

const onSessionUpdate = (event: CustomEvent<LoginSession>) => {
  const session = event.detail;
  loginSessionStore.set(session);
  if (session.isLoggedIn && !!session.user) {
    setUser(session.user?.sub);
  }
};

export const getFreshSession$ = from(fetchInitialSession()).pipe(
  distinctUntilChanged((prev, curr) => {
    return (
      prev?.accessToken === curr?.accessToken &&
      prev?.isLoggedIn === curr?.isLoggedIn &&
      prev?.isExpired === curr?.isExpired &&
      prev?.isOffgrid === curr?.isOffgrid
    );
  }),
  switchMap(async (session) => {
    if (
      (session?.isLoggedIn && session?.isExpired) ||
      (session?.isLoggedIn && session?.isOffgrid)
    ) {
      try {
        const refreshedSession = await loginClient.forceRefresh();
        loginSessionStore.set(refreshedSession);
        // We track how many users end up in this state.
        trackEvent({
          action: 'error:session-expired',
        });
        return refreshedSession;
      } catch (error: unknown) {
        Sentry.captureException(error);
        trackEvent({
          action: 'error:session-failure-refresh',
        });
        return session;
      }
    }
    return session;
  }),
  switchMap(async (session) => {
    try {
      if (session.isLoggedIn) {
        await saveUserInfo(session);
      }
    } catch (error: unknown) {
      Sentry.captureException(error);
      trackEvent({
        action: 'error:save-userinfo',
      });
      throw error;
    }
    return session;
  }),
  catchError((error: unknown) => {
    trackEvent({
      action: 'error:session-failure',
    });
    Sentry.captureException(error);
    return of(null);
  }),
  shareReplay({ refCount: true, bufferSize: 1 })
);

export const saveUserInfo = async (session: LoginSession) => {
  // TODO: What to do if the name of a user cannot be retrieved and can this actually happen?
  const userInfo: UserInfo = {
    firstName: session.user?.name ?? '',
  };
  await lastValueFrom(
    fromFetch(`${import.meta.env.VITE_API_URL}/api/authenticated/userinfo`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${session.accessToken}`,
      },
      body: JSON.stringify(userInfo),
    }).pipe(checkNoContentSuccess(), timeout(6_000), retryFetch(4))
  );
};
