const updateVh = () => {
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  );
};
updateVh();
window.addEventListener('resize', updateVh);

document.documentElement.style.setProperty(
  '--initialVh',
  `${window.innerHeight * 0.01}px`
);
