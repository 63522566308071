<script lang="ts">
  import { switchMap, tap } from 'rxjs';
  import { onMount } from 'svelte';
  import { fetchAnswerSubject } from './api/answers.js';
  import { clientState$ } from './api/clientState.js';
  import { state } from './api/combinedClientState.js';
  import { fetchTotalScore, totalScore$ } from './api/totalScore';
  import AfterContent from './components/AfterContent.svelte';
  import CoreDialog from './components/core/CoreDialog.svelte';
  import LoginDialog from './components/Dialogs/LoginDialog.svelte';
  import WelcomeDialog from './components/Dialogs/WelcomeDialog.svelte';
  import FeedbackLink from './components/Feedback/FeedbackLink.svelte';
  import Footer from './components/Footer.svelte';
  import Logo from './components/Icons/Logo.svelte';
  import JoinNewLeague from './components/League/JoinNewLeague.svelte';
  import Header from './components/Login/Header.svelte';
  import {
    getFreshSession$,
    loginSessionStore,
  } from './components/Login/loginSessionStore';
  import Points from './components/Points.svelte';
  import Poster from './components/Poster.svelte';
  import NewQuestionsToast from './components/Questions/NewQuestionsToast.svelte';
  import Tabs from './components/Tabs.svelte';
  import selectedTabStore from './stores/selectedTabStore.js';
  import { fromSvelteStore } from './util/rxjs.svelte.js';
  import { trackPageView } from './util/snowplow';
  import { getItem, setItem } from './util/storage.js';

  state.subscribe();
  getFreshSession$.subscribe();
  fromSvelteStore(selectedTabStore)
    .pipe(
      switchMap(({ name }) =>
        getFreshSession$.pipe(tap(() => trackPageView(name)))
      )
    )
    .subscribe();

  let showWelcomeDialog = getItem('local', 'welcome-message-skinm', true);

  onMount(() => {
    fetchAnswerSubject.next({ cacheRefresh: false });
    fetchTotalScore.next({ cacheRefresh: false });
    showWelcomeDialog && welcomeDialog.show();
  });

  let welcomeDialog: CoreDialog;
  $: isAfter = $clientState$.state === 'After';

  $: shouldShowEndScreen =
    isAfter && $loginSessionStore?.isLoggedIn && $state && $totalScore$?.score;
  $: {
    if (shouldShowEndScreen) {
      document.body.classList.add('after-background');
    } else {
      document.body.classList.remove('after-background');
    }
  }
</script>

<div class="content">
  <div class="top">
    <Header />
    <main>
      <Logo />
      <h1>Gjettekonken</h1>
      {#if $clientState$.poster?.level === 'Error'}
        <div class="error-poster-wrapper">
          <Poster poster={$clientState$.poster} />
        </div>
      {:else if shouldShowEndScreen}
        <AfterContent state={$state} />
      {:else}
        <Points />
        <Tabs />
      {/if}
    </main>
  </div>
  <div class="bottom">
    <FeedbackLink />
    <NewQuestionsToast />
    <Footer />
    <JoinNewLeague />
    <CoreDialog
      bind:this={welcomeDialog}
      on:close={() => setItem('local', 'welcome-message-skinm', false)}
      id="welcome-dialog"
    >
      <WelcomeDialog {welcomeDialog} />
    </CoreDialog>
  </div>
</div>
<LoginDialog />

<style>
  :global(.after-background) {
    transition: all 1s;
    background:
      radial-gradient(
          135.17% 35.12% at 50% 0%,
          #b21002 0%,
          rgba(232, 21, 2, 0.35) 49%,
          rgba(232, 21, 2, 0) 100%
        )
        no-repeat,
      #feb62d;
    background-repeat: no-repeat, no-repeat, no-repeat;
    --height: calc(150 * var(--initialVh));
    background-size:
      auto var(--height),
      auto var(--height),
      auto var(--height),
      auto auto;
    background-attachment: fixed;
    z-index: -1;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
  }

  main {
    width: 100%;
    padding: 0 0.25rem;
    text-align: center;
  }

  .bottom {
    width: 100%;
  }

  h1 {
    text-transform: uppercase;
    font-weight: 800;
    font-style: italic;
  }

  .error-poster-wrapper {
    margin-top: 1rem;
  }
</style>
