<script lang="ts">
  import { nrkClose, nrkInfo } from '@nrk/core-icons';
  import { onDestroy, onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { fade } from 'svelte/transition';
  import {
    hasSeenScoreboardInfo,
    hasSeenScoreboardInfoID,
  } from '../stores/infoStores.js';
  import { setItem } from '../util/storage.js';

  let scoreboardTimeout: number;
  onMount(() => {
    if (get(hasSeenScoreboardInfo)) {
      return;
    }
    scoreboardTimeout = window.setTimeout(() => {
      setItem('local', hasSeenScoreboardInfoID, true);
      hasSeenScoreboardInfo.set(true);
    }, 10000);
  });
  onDestroy(() => {
    clearTimeout(scoreboardTimeout);
  });
</script>

<div class="content" out:fade>
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  <span class="icon">{@html nrkInfo}</span>
  Resultatene oppdateres etter noen sekunder.
  <button
    class="icon"
    on:click={() => {
      setItem('local', hasSeenScoreboardInfoID, true);
      hasSeenScoreboardInfo.set(true);
      clearTimeout(scoreboardTimeout);
    }}
  >
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkClose}
  </button>
</div>

<style>
  .content {
    flex-grow: 1;
    color: #ffc7bd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    padding: 1rem;
    border: 0.125rem dashed var(--NRK-red-800, #7b0a02);
    background: #1c0001;
    margin: 1rem 0;
  }
  .icon {
    display: flex;
  }

  @media (max-width: 29.0625rem) {
    .icon {
      align-self: flex-start;
    }
  }
</style>
