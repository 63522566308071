import { writable } from 'svelte/store';
import { getItem } from '../util/storage.js';

export const hasSeenScoreboardInfoID = 'has-seen-scoreboard-info-skinm';
export const hasSeenBroadcastInfoID = `has-seen-broadcast-info-skinm-${new Date().getDay()}`;

export const hasSeenScoreboardInfo = writable<boolean>(
  getItem('local', hasSeenScoreboardInfoID)
);

export const hasSeenBroadcastInfo = writable<boolean>(
  getItem('local', hasSeenBroadcastInfoID)
);
