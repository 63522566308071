<script lang="ts">
  import { nrkWarning } from '@nrk/core-icons';
</script>

<div class="no-scoreboard-box">
  <h2 class="no-scoreboard-header">Klarte ikke å laste inn Norgesligaen</h2>
  <span class="no-scoreboard-icon">
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkWarning}
  </span>
  <p class="no-scoreboard-text">
    Dette tok lenger tid enn forventet. Vi jobber med saken og er
    forhåpentligvis straks tilbake.
  </p>
  <p class="no-scoreboard-text">Takk for tålmodigheten.</p>
</div>

<style>
  .no-scoreboard-box {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #1c0001;
    height: 21rem;
  }
  h2 {
    all: unset;
  }
  .no-scoreboard-text {
    text-align: left;
    width: 100%;
  }

  .no-scoreboard-icon {
    font-size: 2rem;
  }

  .no-scoreboard-header {
    font-weight: 700;
    font-size: 1.5rem;
    align-self: flex-start;
    margin: 0;
  }
</style>
