<script lang="ts">
  import { state } from '../../api/combinedClientState.js';
  import { isClosed } from '../../util/questionState.js';

  import { currentQuestionId } from '../../util/questionStateStore.js';
  import Pill from '../Pill.svelte';
  import MultipleChoiceQuestion from '../Questions/MultipleChoiceQuestion.svelte';
  import ValueQuestion from '../Questions/ValueQuestion.svelte';
  import type CoreDialog from '../core/CoreDialog.svelte';

  export let questionDialog: CoreDialog;
  const getCurrentQuestion = (
    questionId: string,
    questions: typeof $state.questions
  ) => {
    if (questionId == null) {
      return;
    }
    return questions.find((q) => q.id === questionId);
  };

  $: currentQuestion = getCurrentQuestion($currentQuestionId, $state.questions);
  $: closed = isClosed(currentQuestion);

  const getColor = () => {
    if (currentQuestion?.score === 500) return 'gold';
    if (currentQuestion?.score === 250) return 'silver';
    return 'bronze';
  };
</script>

{#key $currentQuestionId}
  <div class="dialog-content dark-card">
    {#if currentQuestion}
      <Pill question={currentQuestion} dark={false} big />
      {#if currentQuestion.type === 'MultipleChoice'}
        <div class="question">
          <MultipleChoiceQuestion
            answered={true}
            {questionDialog}
            question={currentQuestion}
          />
        </div>
      {:else}
        <div class="question">
          <ValueQuestion
            answered={true}
            {questionDialog}
            question={currentQuestion}
          />
        </div>
      {/if}
      {#if !currentQuestion.answer}
        <div class="closed-text">
          Du rakk ikke svare på dette spørsmålet innen fristen
        </div>
      {:else if closed}
        <div class="closed-text">
          Spørsmålene låses før svaret kan avsløres i sendingen
        </div>
      {/if}
    {/if}
    <div class={getColor()}>{`Opptil ${currentQuestion?.score} poeng`}</div>
  </div>
{/key}

<style>
  .dialog-content {
    display: grid;
    justify-items: center;
    grid-template-columns: 16.4375rem;
    padding-bottom: 0;
    grid-template-rows: 1.5rem auto 2.5rem;
  }

  .question {
    width: 100%;
    align-self: flex-start;
    margin-top: 2rem;
  }
  .gold,
  .silver,
  .bronze {
    align-self: flex-end;
    width: 20.4375rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    font-size: 0.875rem;
    font-weight: 560;
  }

  .closed-text {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-style: italic;
    color: rgb(196, 196, 196);
    font-family: NRK Sans Variable;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    position: absolute;
    max-width: 16.4375rem;
    bottom: 4rem;
  }
</style>
