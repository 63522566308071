<script lang="ts">
  import { nrkSpinner } from '@nrk/core-icons';
  import type { Question } from '@pin/schemas/clientSchema';
  import { fetchAnswerSubject } from '../../api/answers.js';
  import { revealQuestion } from '../../util/revealQuestion.js';
  import { impression, trackEvent } from '../../util/snowplow.js';
  import type CoreDialog from '../core/CoreDialog.svelte';
  import Pill from '../Pill.svelte';
  export let revealDialog: CoreDialog;
  export let readyForReveal: Question[];
  let revealing = false;
</script>

<button
  class="wrapper card"
  disabled={revealing}
  aria-busy={revealing}
  on:click={async () => {
    revealing = true;
    trackEvent({ action: 'click:reveal-card' });
    fetchAnswerSubject.next({ cacheRefresh: true });
    await revealQuestion(readyForReveal, 0);
    revealing = false;
    revealDialog.show();
  }}
  use:impression={'reveal-card'}
>
  <div class="top">
    <Pill />
    <div class="header">Se om du gjettet riktig</div>
    <div class="number">
      <span class="text-gradient" aria-hidden="true"
        >{readyForReveal.length}</span
      >
      {readyForReveal.length}
    </div>
  </div>
  <div class="check-button">
    {#if revealing}
      Sjekker <span class="inline-spinner">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkSpinner}
      </span>
    {:else}
      Sjekk svar
    {/if}
  </div>
</button>

<style>
  .wrapper {
    display: grid;
    justify-items: center;
    overflow: hidden;
    color: var(--NRK-gray-950, #131317);
    justify-content: space-between;
    font-weight: 560;
    background: var(--NRK-cool-orange-500, #ffa90a);
  }

  @supports (conic-gradient(black, white)) {
    .wrapper {
      background: conic-gradient(
          from 223deg at 93.82% 3.53%,
          rgba(0, 0, 0, 0) 0deg,
          rgba(0, 0, 0, 0.02) 360deg
        ),
        var(--NRK-cool-orange-500, #ffa90a);
    }
  }

  .header {
    text-align: left;
    margin-top: 0.6rem;
  }

  .number {
    position: relative;
    text-align: center;
    font-size: 5.275rem;
    font-weight: 700;
    background: linear-gradient(
      180deg,
      #ffaa05 0%,
      #e39706 38.63%,
      #462d08 46.68%,
      #e39706 55.26%,
      #ffaa05 78.87%,
      #462d08 91.21%,
      #ffaa05 100%
    );
    background-size: 100% 85%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.3125rem transparent;
    filter: drop-shadow(0rem 0rem 0.25rem #9842008a)
      drop-shadow(0rem 0rem 0.8125rem #9842008a);
  }

  .number .text-gradient {
    position: absolute;
    background: linear-gradient(
      152.14deg,
      #ffd600 0%,
      #faa005 40.96%,
      #eea400 49.3%,
      #ffc700 54.37%,
      #faa005 68.67%,
      #eca000 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.0625rem transparent;
    z-index: 2;
  }

  .check-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: var(--NRK-gray-100, #ebe7e6);
    background: var(--NRK-gray-950, #131317);
    width: 100%;
    padding: 0.25rem 0;
    font-weight: 700;
    font-stretch: expanded;
    height: 1.875rem;
    border-radius: 2px;
  }

  button:focus .check-button {
    box-shadow: var(--inverted-focus-box);
  }
</style>
