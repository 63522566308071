<script lang="ts">
  import { nrkSomeShareIos } from '@nrk/core-icons';
  import { nrkLogoNrk } from '@nrk/core-icons/logo';
  import type { SimpleLeague } from '@pin/schemas/leagueSchema';
  import * as Sentry from '@sentry/svelte';
  import { onDestroy } from 'svelte';
  import { trackEvent } from '../../util/snowplow.js';
  export let league: SimpleLeague;

  let fallbackCopiedToClipBoard = false;
  let copiedToClipBoard = false;

  const baseUtmParams = `&utm_campaign=gjettekonken&utm_source=league`;
  const qrcampaignParams = `${baseUtmParams}&utm_medium=qr`;
  const linkcampaignParams = `${baseUtmParams}&utm_medium=link`;
  const copyLinkcampaignParams = `${baseUtmParams}&utm_medium=copylink`;
  const url = `${window.location.origin}/${import.meta.env.VITE_URL_PATH}?leagueId=${league.id}`;
  const searchParams = new URLSearchParams(window.location.search);

  const clientIdParams = searchParams.get('client-id')
    ? `&client-id=${searchParams.get('client-id')}`
    : '';
  const qrImageUrl = `https://qr.nrk.no/api/generate?url=${encodeURI(url + qrcampaignParams + clientIdParams)}&size=512&logo=nrk&&color=000000&bgcolor=FFFFFF&borderRadius=8`;

  let timeoutId: number | undefined;
  const handleShareLink = async () => {
    trackEvent({ action: 'click:sharelink' });

    const linkUrl = url + linkcampaignParams + clientIdParams;
    const data = {
      title: 'Hei!',
      text: `Jeg ønsker å invitere deg til venneligaen min ${league.name}.\n
            I Gjettekonken til NRK kan man gjette på spørsmål til NM på ski og samle poeng mens man ser på sendingen.\n
            Klikk på lenken for å bli med.`.replace(/  +/g, ' '),
      url: linkUrl,
    };
    if (navigator.share) {
      try {
        await navigator.share(data);
      } catch (e) {
        Sentry.captureException(e);
      }
      return;
    }
    try {
      await navigator.clipboard.writeText(
        url + copyLinkcampaignParams + clientIdParams
      );
      fallbackCopiedToClipBoard = true;
      timeoutId = window.setTimeout(() => {
        fallbackCopiedToClipBoard = false;
      }, 2000);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        url + copyLinkcampaignParams + clientIdParams
      );
      copiedToClipBoard = true;
      timeoutId = window.setTimeout(() => {
        copiedToClipBoard = false;
      }, 2000);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  onDestroy(() => {
    clearTimeout(timeoutId);
  });
</script>

<div class="wrapper">
  <span class="logo">
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkLogoNrk}
  </span>
  <h2 tabindex="-1">{`Inviter deltakere til ${league?.name}`}</h2>
  <img src={qrImageUrl} alt="qr-kode" />
  <p>Skann QR-koden eller send en invitasjon med knappen under.</p>
  <button on:click={handleShareLink} class:fallbackCopiedToClipBoard>
    {#if fallbackCopiedToClipBoard}
      <span>Kopiert</span>
    {:else}
      <span>
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkSomeShareIos}
      </span>
      Inviter til liga
    {/if}
  </button>
  <button class="copybutton" class:copiedToClipBoard on:click={copyLink}>
    <span>
      <svg
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9 13H11C10.45 13 10 13.45 10 14V17.9H7C5.952 17.9 5.1 17.048 5.1 16V7C5.1 5.952 5.952 5.1 7 5.1H13C14.048 5.1 14.9 5.952 14.9 7V13ZM4 7V13.9H3C1.952 13.9 1.1 13.048 1.1 12V3C1.1 1.952 1.952 1.1 3 1.1H9C10.048 1.1 10.9 1.952 10.9 3V4H7C5.35 4 4 5.35 4 7ZM13 4H12V3C12 1.35 10.65 0 9 0H3C1.35 0 0 1.35 0 3V12C0 13.65 1.35 15 3 15H4V16C4 17.65 5.35 19 7 19H9.929L15.999 12.929V7C15.999 5.35 14.649 4 12.999 4H13Z"
          fill="currentColor"
        />
      </svg>
    </span>{#if copiedToClipBoard}
      <span>Kopiert!</span>
    {:else}
      Kopier lenke
    {/if}
  </button>
</div>

<style>
  h2 {
    color: white;
    background: unset;
    font-weight: 700;
    font-size: 1.5rem;
    overflow-wrap: anywhere;
  }
  h2:focus {
    box-shadow: none;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem;
    min-width: 20.625rem;
    width: 100%;
    max-width: var(--max-width);
    justify-content: space-around;
    background: black;
    color: white;
  }

  .logo {
    font-size: 2rem;
  }
  img {
    height: 10rem;
    width: 10rem;
  }

  p {
    text-align: left;
  }

  button {
    background: var(--NRK-gray-100, #ebe7e6);
    color: black;
    height: 2.5625rem;
    font-weight: 800;
    min-width: 14rem;
    align-self: center;
    padding: 0.5rem 2rem;
    display: inline-flex;
    justify-content: center;
    gap: 0.3rem;
    align-items: center;
    position: relative;
  }

  button.copiedToClipBoard,
  button.fallbackCopiedToClipBoard {
    background: var(--NRK-cool-green-400, #5dc479);
    color: white;
  }

  .copybutton {
    color: var(--NRK-gray-100, #ebe7e6);
    background: black;
    border: 0.0625rem solid var(--NRK-gray-100, #ebe7e6);
    gap: 0.5rem;
  }
</style>
