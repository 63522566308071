<script lang="ts">
  import {
    nrkArticle,
    nrkChevronDown,
    nrkHardwareTv,
    nrkInfo,
    nrkPerson,
    nrkStar,
    nrkUserLoggedin,
  } from '@nrk/core-icons';
  import CoreToggle from './core/CoreToggle.svelte';

  import { shouldOpenLeaugeDialog } from '../stores/openLeagueDialog.js';
  import selectedTabStore, { tabs } from '../stores/selectedTabStore.js';
  import { impression } from '../util/snowplow.js';
  import Cards from './Icons/Cards.svelte';
  import Trophy from './Icons/Trophy.svelte';
  import { loginSessionStore } from './Login/loginSessionStore.js';

  const handleClick = () => {
    $selectedTabStore = tabs[1];
    shouldOpenLeaugeDialog.set(true);
  };
</script>

<div class="content">
  <button class="expand-button" type="button" data-for="Gjettekonken">
    <span class="cards-icon icon center">
      <Cards orange={true} />
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Om Gjettekonken</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Gjettekonken" closeOnOutsideClick={false}>
    <p class="text">
      Gjettekonken er NRKs nye, uformelle gjettekonkurranse for hele familien
      –hvor du kan gjette og konkurrere underveis i direktesendingene til NM på
      ski.
      <br /><br />
      Det er en ny konkurranse per øvelse i NM.
    </p>
  </CoreToggle>
</div>

<div class="content">
  <button class="expand-button" type="button" data-for="Hvor går sendingen?">
    <span class="icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkHardwareTv}
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Hvor går sendingen?</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Hvor går sendingen?" closeOnOutsideClick={false}>
    <p class="text">
      Du kan se sendingen på NRK 1 eller i
      <a class="help-link" href="https://tv.nrk.no/direkte/nrk1"> NRK TV </a>.
    </p>
  </CoreToggle>
</div>

{#if !$loginSessionStore?.isLoggedIn}
  <div class="content">
    <button class="expand-button" type="button" data-for="Logg på NRK">
      <span class="icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkPerson}
      </span>
      <div class="name-chevron-wrapper">
        <span class="name">Logg på NRK</span>
        <span class="expand-icon center">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {@html nrkChevronDown}
        </span>
      </div>
    </button>
    <CoreToggle id="Logg på NRK" closeOnOutsideClick={false}>
      <p class="text">
        For å delta i Gjettekonken må du logge på med din NRK-profil.
        <br /><br />
        Har du ikke NRK-profil fra før kan du enkelt
        <a class="help-link" href="https://innlogging.nrk.no/logginn">
          opprette en ny her</a
        >.
      </p>
    </CoreToggle>
  </div>
{/if}

<div class="content">
  <button class="expand-button" type="button" data-for="Slik spiller du">
    <span class="icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkInfo}
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Slik spiller du</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Slik spiller du" closeOnOutsideClick={false}>
    <p class="text">
      Gjennom sendingene kommer det nye spørsmål du kan gjette på i
      Gjettekonken.
      <br /><br />
      Når du har svart på alle spørsmål som ligger under “Spill” får du beskjed om
      når neste pulje blir sluppet. Dette vil også vises på sendingen.
      <br /><br />
      Spørsmålene låses kort tid før hver øvelse, og de som er i ferd med å låses
      blir markert.
      <br /><br />
      Når en øvelse er ferdig kommer resultatene inn i Gjettekonken og du kan sjekke
      hvor mange poeng du har fått og hvor du ligger på resultatlista.
    </p>
  </CoreToggle>
</div>

<div class="content">
  <button class="expand-button" type="button" data-for="Poengsystem">
    <span class="icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkStar}
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Poengsystem</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Poengsystem" closeOnOutsideClick={false}>
    <p class="text">
      Gjetter du riktig vil du få poeng. Hvor mange poeng du får avhenger av
      spørsmålets vanskelighetsgrad og hvor nære du er fasit.
      <br /><br />
      Det lønner seg å svare raskt, da kan du få tidsbonus.
      <br /><br />
      Poengene avgjør hvem som vinner hver konkurranse. I tillegg samler du poeng
      i sammenlagt-lista gjennom hele NM.
    </p>
  </CoreToggle>
</div>

<div class="content">
  <button class="expand-button" type="button" data-for="Venneliga">
    <span class="icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkUserLoggedin}
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Venneliga</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Venneliga" closeOnOutsideClick={false}>
    {#if !$loginSessionStore?.isLoggedIn}
      <p class="text">
        Du kan opprette egne venneligaerog konkurrere mot venner og familie. Du
        kan være med i opp til 10 venneligaer.
      </p>
    {:else}
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <p class="text" on:click={handleClick}>
        Du kan <span class="help-link">opprette egne venneligaer</span> og konkurrere
        mot venner og familie. Du kan være med i opp til 10 venneligaer.
      </p>
    {/if}
  </CoreToggle>
</div>

<div class="content">
  <button class="expand-button" type="button" data-for="Premier">
    <span class="icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <Trophy selected={true} />
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Premier</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Premier" closeOnOutsideClick={false}>
    <p class="text">
      Vinneren av hver konkurranse vinner en Gjettekonken-kopp.
      <br /><br />
      Det trekkes én tilfeldig vinner av en NRK-hals i hver konkurranse.
      <br /><br />
      Når siste øvelse er ferdig søndag, kårer vi sammenlagtvinneren som vinner 2
      billetter til ski-VM i februar!
      <br /><br />
      Vinnerne vil bli kontaktet på e-post.
    </p>
  </CoreToggle>
</div>

<div class="content">
  <button class="expand-button" type="button" data-for="Deltagelse i NRK">
    <span class="icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkArticle}
    </span>
    <div class="name-chevron-wrapper">
      <span class="name">Deltagelse i NRK</span>
      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>
  <CoreToggle id="Deltagelse i NRK" closeOnOutsideClick={false}>
    <p class="text" use:impression={'personvern-in-rules'}>
      Lurer du på noe om deltakelse i NRK kan du lese <a
        class="help-link"
        href="https://info.nrk.no/personvernerklaering/"
      >
        personvernerklæringen</a
      >.
    </p>
  </CoreToggle>
</div>

<style>
  .content {
    text-align: left;
    background: #1c0001;
  }

  .content:not(:last-child) {
    margin-bottom: 0.2rem;
  }

  .expand-button {
    display: flex;
    padding: 1rem;
    gap: 0.9375rem;
    width: 100%;
  }

  .cards-icon {
    transform: scale(0.8);
  }

  .icon {
    color: #ffa90a;
    max-width: 1.5rem;
  }

  .name {
    font-weight: 700;
  }
  .name-chevron-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text {
    width: 100%;
    padding: 1.5rem 1rem 2rem;
    font-weight: 300;
  }

  .help-link {
    color: #ffa90a;
    font-weight: 700;
    text-decoration: underline;
  }

  .help-link:hover {
    cursor: pointer;
  }
</style>
