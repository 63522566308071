<script lang="ts">
  import { nrkSomeShareIos } from '@nrk/core-icons';
  import type { League } from '@pin/schemas/leagueSchema';
  import { trackEvent } from '../../util/snowplow.js';
  import CoreDialog from '../core/CoreDialog.svelte';
  import ShareDialog from './ShareDialog.svelte';

  export let league: League;
  let visible = false;
</script>

<button
  class="tertiary-button"
  on:click={() => {
    visible = true;
    trackEvent({ action: 'click:inviteToLeague' });
  }}
  ><span>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkSomeShareIos}
  </span>Inviter deltakere</button
>
{#if visible}
  <CoreDialog
    id={`invite-league-${league.id}`}
    visible={true}
    on:close={() => (visible = false)}
  >
    <div>
      <ShareDialog {league} />
    </div>
  </CoreDialog>
{/if}

<style>
  button {
    min-width: 14rem;
    font-weight: 700;
    font-size: 1rem;
    background: rgb(28, 0, 1);
  }
</style>
