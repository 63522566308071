<script lang="ts">
  import { trackEvent } from '../../util/snowplow.js';
  import TrophyDetailed from '../Icons/TrophyDetailed.svelte';
  import { loginModel } from '../Login/loginModel.js';

  export let showLoginButton = false;
</script>

<div class="icon">
  <TrophyDetailed />
</div>

<div class="podium">
  {#each Array(3) as _, i}
    <div
      class="spot"
      class:first={i === 0}
      class:second={i === 1}
      class:third={i === 2}
      role="img"
    >
      <div class="podium-rank" aria-hidden="true">{i + 1}</div>
      <div class="podium-name" aria-hidden="true"></div>
    </div>
  {/each}
</div>
{#if showLoginButton}
  <div class="text">
    Bli med i Norgesligaen og konkurrer mot resten av Norge. Alle som deltar er
    med i trekningen av gjeve premier!
  </div>
  <button
    class="tertiary-button"
    on:click={() => {
      loginModel.login();
      trackEvent({ action: 'click:login-dialog-norgesliga' });
    }}>Logg på NRK</button
  >
{:else}
  <div class="text">Ingen resultater enda</div>
{/if}

<style>
  .icon {
    margin-top: 2.5rem;
  }

  .text {
    margin: 1rem;
  }

  button {
    margin: 1rem 0 2rem;
  }
  .podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 9.375rem;
    margin-bottom: 2rem;
  }

  .podium .spot {
    width: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin: 0.0313rem;
  }

  .spot.first {
    order: 2;
    z-index: 3;
    height: 7.5rem;
    background: var(--NRK-cool-orange-500, #ffa90a);
    box-shadow:
      0rem 0.75rem 0.625rem 0rem rgba(0, 0, 0, 0.41),
      0rem 0.375rem 0.375rem 0rem rgba(0, 0, 0, 0.34),
      0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.23);
  }

  .spot.second {
    order: 1;
    z-index: 2;
    height: 5.625rem;
    background: var(--NRK-warm-orange-600, #f56a00);
  }

  .spot.third {
    order: 3;
    height: 4.375rem;
    background: var(--NRK-warm-orange-800, #7c3500);
  }

  .podium-name {
    position: absolute;
    top: -1.5625rem;
    font-weight: bold;
    font-size: 1.2rem;
    max-width: 6rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .podium-rank {
    margin-bottom: 0.5rem;
    color: var(--NRK-gray-950, #131317);
    text-shadow: 0rem 0.0625rem 0rem hsla(18, 73%, 40%, 1);
    font-size: 1.8rem;
    font-weight: 700;
    line-height: normal;
  }
</style>
