<script lang="ts">
  export let text: string;
  export let name: string;
  export let group: number;
  export let value: number;
</script>

<label>
  <input type="radio" bind:group {value} {name} />
  <span class="emojiwrapper" aria-hidden="true"><slot /></span>
  <span class="emojitext">{text}</span>
</label>

<style>
  label {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    max-width: 3.75rem;
    width: 100%;
    position: relative;
    margin: 0.25rem;
  }

  label:hover {
    cursor: pointer;
  }

  input {
    appearance: none;
    position: absolute;
    top: -0.25rem;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 3.375rem;
    padding: 0.3125rem 0;
  }

  .emojitext {
    margin-top: 0.25rem;
  }

  input:checked {
    /* border-radius: .25rem; */
    background-color: var(--sport-red);
  }

  .emojiwrapper {
    z-index: 2;
  }
</style>
