<script lang="ts">
  export let orange = false;
  export let black = false;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="38"
  height="20"
  viewBox="0 0 38 20"
  fill={black ? '#131317' : orange ? '#FFA90A' : 'currentColor'}
>
  <path
    d="M17.5582 17.867H23.2637C24.3102 17.867 25.1586 17.025 25.1586 15.9863V1.88074C25.1586 0.842035 24.3102 0 23.2637 0H14.7364C14.3975 0 14.0793 0.0883054 13.8041 0.242999C14.0188 0.568753 14.1858 0.933401 14.2929 1.3299L17.9712 14.9548C18.2484 15.9815 18.0697 17.0228 17.5582 17.867Z"
  />
  <path
    d="M0.565039 4.98067C0.294172 3.97736 0.894079 2.94608 1.90497 2.67724L10.1417 0.486777C11.1526 0.217941 12.1916 0.81335 12.4625 1.81666L16.1409 15.4416C16.4117 16.4449 15.8118 17.4761 14.8009 17.745L6.56423 19.9354C5.55334 20.2043 4.51427 19.6089 4.24341 18.6056L0.565039 4.98067Z"
  />
  <path
    d="M31.4358 19.9354L26.0743 18.5096C26.6828 17.8423 27.0535 16.9572 27.0535 15.9863V1.88075C27.0535 1.39734 26.9617 0.935231 26.7943 0.510684C27.1279 0.40518 27.495 0.390148 27.8583 0.486782L36.095 2.67725C37.1059 2.94609 37.7058 3.97736 37.435 4.98067L33.7566 18.6056C33.4857 19.6089 32.4467 20.2043 31.4358 19.9354Z"
  />
</svg>

<style>
  svg {
    margin-bottom: 0.1875rem;
  }
</style>
