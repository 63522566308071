<script lang="ts">
  import type { Question } from '@pin/schemas/clientSchema';
  import { currentQuestionId } from '../../util/questionStateStore.js';
  import { impression, trackEvent } from '../../util/snowplow.js';
  import { openedQuestionKey, setItem } from '../../util/storage.js';
  import type CoreDialog from '../core/CoreDialog.svelte';
  import { loginDialogStore } from '../Dialogs/loginDialogStore.js';
  import { loginSessionStore } from '../Login/loginSessionStore.js';
  import Pill from '../Pill.svelte';
  export let questionDialog: CoreDialog;
  export let question: Question;

  const getColor = () => {
    if (question.score === 500) return 'gold';
    if (question.score === 250) return 'silver';
    return 'bronze';
  };
</script>

<button
  class="wrapper open-question-button card"
  data-id={question.id}
  use:impression={`question-${question.id}-${question.status}`}
  on:click={() => {
    if ($loginSessionStore?.isLoggedIn) {
      currentQuestionId.set(question.id);
      questionDialog.show();
      trackEvent({ action: 'click:open-card' });
    } else {
      trackEvent({ action: 'click:open-card-to-login' });
      setItem('session', openedQuestionKey, question.id);
      $loginDialogStore?.show();
    }
  }}
>
  <Pill {question} />
  <div class="question-text">{question.text}</div>
  <div class="guess-button">Gjett</div>
  <div class={getColor()}></div>
</button>

<style>
  .wrapper {
    display: grid;
    justify-items: center;
    overflow: hidden;
    grid-template-rows: 0.875rem 10rem 2.5rem 0.625rem;
    color: var(--NRK-gray-950, #131317);
    background: var(--NRK-gray-100, #ebe7e6);
    font-weight: 560;
    padding: var(--400, 1rem);
  }

  @supports (conic-gradient(black, white)) {
    .wrapper {
      background: conic-gradient(
          from 223deg at 93.82% 3.53%,
          rgba(0, 0, 0, 0) 0deg,
          rgba(0, 0, 0, 0.02) 360deg
        ),
        var(--NRK-gray-100, #ebe7e6);
    }
  }

  .question-text {
    max-width: 7.5rem;
    margin-top: 1rem;
    align-self: flex-start;
    word-break: break-word;
    text-align: left;
  }

  .guess-button {
    display: flex;
    font-stretch: expanded;
    justify-content: center;
    align-items: center;
    border-radius: 0.125rem;
    text-transform: uppercase;
    background: var(--NRK-gray-950, #131317);
    color: white;
    padding: 0.25rem 0;
    font-weight: 700;
    width: 100%;
    height: 1.875rem;
  }

  button:focus .guess-button {
    box-shadow: var(--inverted-focus-box);
  }

  .gold,
  .silver,
  .bronze {
    width: 140%;
    height: 0.625rem;
  }
</style>
