import type { AnswerHistory } from '@pin/schemas/clientSchema';
import {
  BehaviorSubject,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { getFreshSession$ } from '../components/Login/loginSessionStore';
import { jsonSuccessSelector, retryFetch } from '../util/rxjs.http';
import { trackEvent } from '../util/snowplow';
import { getClientId } from './clientId';
import { clientState$ } from './clientState';

export const shouldFetchHistory = new BehaviorSubject<boolean>(true);
export const fetchHistory = new Subject<{ cacheRefresh: boolean }>();
const fetchHistoryObservable = () =>
  fetchHistory.pipe(
    switchMap(({ cacheRefresh }) =>
      from(getFreshSession$).pipe(
        switchMap((session) =>
          clientState$.pipe(
            map(({ poster }) => poster?.level !== 'Error'),
            filter((v) => v),
            distinctUntilChanged(),
            switchMap(() =>
              session?.accessToken != null
                ? fromFetch(
                    `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/history`,
                    {
                      headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${session.accessToken}`,
                      },
                      cache: cacheRefresh ? 'reload' : 'default',
                      selector: jsonSuccessSelector<AnswerHistory>,
                    }
                  ).pipe(
                    timeout(5_000),
                    retryFetch(3),
                    catchError((_err: unknown, source) => {
                      trackEvent({ action: 'error:fetching-answer-history' });
                      return timer(5_000).pipe(switchMap(() => source));
                    })
                  )
                : of(null)
            )
          )
        )
      )
    )
  );
export const history$ = fetchHistoryObservable().pipe(
  shareReplay({ bufferSize: 1, refCount: false })
);
