<script lang="ts">
  import { tweened } from 'svelte/motion';
  import { fly } from 'svelte/transition';
  import { totalScore$ } from '../api/totalScore';
  import { latestPoints } from '../util/questionStateStore.js';

  let points = tweened(0, {
    duration: 500,
  });
  let showBonus = false;

  $: points.set($totalScore$?.score ? $totalScore$.score : 0);
</script>

<div class="container">
  <div>{$points.toFixed(0)} poeng</div>
  {#if showBonus && $latestPoints !== 0}
    <div class="bonus" in:fly={{ y: 50, duration: 500 }}>
      +{$latestPoints}
    </div>
  {/if}
</div>

<style>
  .container {
    position: relative;
    margin: 1rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffb800;
    color: black;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 62rem;
    z-index: 9999;
    min-width: 7.75rem;
  }
  .bonus {
    position: absolute;
    top: -2rem;
    color: lime;
    font-weight: bold;
    font-size: 1.2rem;
    opacity: 0.8;
  }
</style>
