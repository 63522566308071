import { getRedirectLoginClient } from '@nrk/innlogging-web/redirect-login-client';

const loginClient = getRedirectLoginClient({});

export const loginModel = {
  loginTestUser: (_username: string) => {
    // noop in prod
  },
  login: () =>
    loginClient.login({
      nrkCtx: 'gjett',
    }),
  logout: () => loginClient.logout(),
  goToUserPage: () => loginClient.navigateTo({ target: 'userpage' }),
};
