<script lang="ts">
  import { nrkSpinner } from '@nrk/core-icons';
  import {
    MAX_LEAGUE_NAME_LENGTH,
    type SimpleLeague,
  } from '@pin/schemas/leagueSchema';
  import { captureException } from '@sentry/svelte';
  import { createLeague, fetchLeaguesSubject } from '../../api/fetchleague';
  import { impression, trackEvent } from '../../util/snowplow.js';
  import Pill from '../Pill.svelte';

  export let shareNewLeague: (league: SimpleLeague) => void;
  let submitting = false;
  let error = false;

  const handleCreateLeague = async (e: SubmitEvent) => {
    error = false;
    const target = e.target as HTMLFormElement;
    const data = new FormData(target);
    const leagueName = String(data.get('leagueName')) || '';

    submitting = true;
    try {
      trackEvent({ action: 'click:create-league' });
      const league = await createLeague({
        leagueName,
      });
      shareNewLeague(league);
      fetchLeaguesSubject.next({ cacheRefresh: true });
    } catch (e) {
      captureException(e);
      error = true;
      setTimeout(() => {
        error = false;
      }, 7_000);
    } finally {
      submitting = false;
    }
  };

  let value = '';
</script>

<div class="wrapper" use:impression={'create-league'}>
  <Pill big />
  <form on:submit|preventDefault={handleCreateLeague}>
    <legend>Opprett venneliga</legend>
    <label>
      Hva skal ligaen din hete?
      <input
        id="leagueName"
        name="leagueName"
        required
        minlength={2}
        maxlength={MAX_LEAGUE_NAME_LENGTH}
        type="text"
        bind:value
      />
    </label>
    <button
      class="create-button"
      class:error
      type="submit"
      disabled={submitting || value.length < 1}
    >
      {#if error}
        <span aria-live="assertive"> Prøv igjen </span>
      {:else}
        Opprett liga
        {#if submitting}
          <span class="inline-spinner">
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            {@html nrkSpinner}
          </span>
        {/if}
      {/if}
    </button>
  </form>
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: var(--max-width);
    gap: 2rem;
    padding: 2rem;
    background: black;
    color: white;
  }

  form {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
  }

  input {
    border-radius: var(--100, 0.25rem);
    border: 0.0625rem solid var(--NRK-gray-300, #b6b3b4);
    border-bottom: 0.3125rem solid var(--NRK-gray-300, #b6b3b4);

    background: var(--NRK-gray-100, #ebe7e6);
    font-size: 1.25rem;
    padding: 0.8rem 1rem 0.5rem;
    margin-top: 0.5rem;
    width: 100%;
  }
  legend {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }

  .create-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--NRK-gray-100, #ebe7e6);
    color: black;
    font-weight: 800;
    min-width: 14rem;
    padding: 0.7rem 2rem;
    margin-top: 1rem;
    align-self: center;
  }

  button.error {
    background: var(--NRK-cool-orange-400, #feb937);
    color: var(--NRK-black, #000);
  }
</style>
