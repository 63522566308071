<script lang="ts">
  import { onMount } from 'svelte';

  let time = new Date();
  export let hour: string | null = null;
  export let minute: string | null = null;

  $: hours = hour ? parseInt(hour) : time.getHours();
  $: minutes = minute ? parseInt(minute) : time.getMinutes();
  $: seconds = hour || minute ? 0 : time.getSeconds();

  onMount(() => {
    const interval = setInterval(() => {
      time = new Date();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
</script>

<svg viewBox="-50 -50 100 100">
  <circle class="clock-face" r="30" />

  <line class="hour" y2="-15" transform="rotate({30 * hours + minutes / 2})" />

  <line
    class="minute"
    y2="-22"
    transform="rotate({6 * minutes + seconds / 10})"
  />

  <circle class="dot" cx="0" cy="0" r="1" />
</svg>

<style>
  svg {
    width: 70%;
  }

  .clock-face {
    stroke: #ffa90a;
    fill: transparent;
    stroke-width: 5;
  }

  .hour,
  .minute,
  .dot {
    stroke: #ffa90a;
    stroke-width: 4;
  }
</style>
