<script lang="ts">
  import { nrkSpinner } from '@nrk/core-icons';
  import type { Question } from '@pin/schemas/clientSchema';
  import { fly } from 'svelte/transition';
  import { state } from '../../api/combinedClientState.js';
  import {
    currentRevealQuestionId,
    revealQuestion,
  } from '../../util/revealQuestion.js';
  import type CoreDialog from '../core/CoreDialog.svelte';
  import Pill from '../Pill.svelte';

  export let revealDialog: CoreDialog;
  export let readyForReveal: Question[];
  let revealing = false;

  const getSolution = (question: Question) => {
    if (question.type === 'MultipleChoice')
      return question.alternatives.find((q) => {
        if (question.solution) {
          return q.id === question.solution;
        }
        return;
      });
    else return { text: question.solution };
  };

  const getNextRevealQuestionIndex = (questions: Question[]) => {
    // Since we cannot guarantee that readyForReveal is updated at this point
    // We try to fid the next question to reveal, and if not found there is no
    // questions left to reveal.
    if (questions.length === 0) {
      return -1;
    }
    const currentQuestionIndex = questions.findIndex(
      (q) => q.id === $currentRevealQuestionId
    );

    const nextQuestionIndex =
      currentQuestionIndex === -1 ? 0 : currentQuestionIndex + 1;
    if (questions[nextQuestionIndex] == null) {
      return -1;
    }
    return nextQuestionIndex;
  };

  $: currentRevealQuestion = $state.questions.find(
    (q) => q.id === $currentRevealQuestionId
  )!;
  $: correct = $currentRevealQuestionId && currentRevealQuestion?.answer?.score;
  $: nextRevealQuestionIndex = getNextRevealQuestionIndex(readyForReveal);
</script>

{#key $currentRevealQuestionId}
  <div
    class="dialog-content"
    out:fly={{ x: -400, duration: 300 }}
    in:fly={{ x: 400, duration: 300 }}
    class:correct
  >
    <div class="top">
      <Pill big />
      {#if currentRevealQuestion}
        <div class="question-text">
          {currentRevealQuestion.text}
        </div>
        <div class="answer-header">Du svarte</div>
        {#if currentRevealQuestion.type === 'MultipleChoice'}
          <div class="answer">
            {currentRevealQuestion.answer?.alternative.text}
          </div>
          <div class="answer-correct">
            {correct ? 'Riktig!' : 'Feil!'}
          </div>
        {:else}
          <div class="answer">{currentRevealQuestion.answer?.value}</div>
        {/if}
        <div class="solution">
          Riktig svar var: {getSolution(currentRevealQuestion)?.text}
        </div>
        <div class:correct class="points">
          {correct ? `+${currentRevealQuestion.answer?.score}` : 0} poeng
        </div>
      {/if}
    </div>
    <div>
      {#if nextRevealQuestionIndex >= 0}
        <button
          class="secondary-button"
          disabled={revealing}
          aria-busy={revealing}
          on:click={async () => {
            revealing = true;

            await revealQuestion(readyForReveal, nextRevealQuestionIndex);
            revealing = false;
          }}
        >
          {#if revealing}
            Sjekker <span class="inline-spinner">
              <!-- eslint-disable-next-line svelte/no-at-html-tags -->
              {@html nrkSpinner}
            </span>
          {:else}
            Sjekk neste gjett
          {/if}</button
        >
      {:else}
        <button class="secondary-button" on:click={revealDialog.close}>
          Lukk</button
        >
      {/if}
    </div>
  </div>
{/key}

<style>
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .secondary-button {
    text-transform: uppercase;
    width: fit-content;
    text-wrap: nowrap;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    min-width: 9.375rem;
  }

  .dialog-content {
    justify-content: space-between;
    position: relative;
    background: linear-gradient(
        204deg,
        rgba(255, 255, 255, 0.5) 1.82%,
        rgba(255, 255, 255, 0) 100%
      ),
      var(--NRK-gray-100, #ebe7e6);
    color: var(--NRK-gray-950, #131317);
  }

  .question-text {
    margin-top: 1rem;
    text-align: left;
  }

  .correct {
    background: linear-gradient(
        204deg,
        rgba(255, 168, 0, 0) 1.82%,
        rgba(255, 168, 0, 0.5) 100%
      ),
      var(--NRK-cool-orange-400, #feb937);
  }

  .answer-header {
    margin-top: 2rem;
  }

  .answer {
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
  }

  .answer-correct {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .points {
    border-radius: 62rem;
    background: var(--NRK-gray-800, #36363b);
    color: var(--NRK-gray-100, #ebe7e6);
    padding: var(--100, 0.25rem) var(--300, 1rem);
  }

  .points.correct {
    background: var(--NRK-cool-orange-600, #e59100);
    color: var(--NRK-gray-950, #131317);
  }

  .secondary-button {
    font-size: 1.2rem;
  }

  .solution {
    transform: rotatez(180deg);
    position: absolute;
    bottom: 1.5rem;
    font-size: 0.875rem;
  }
</style>
