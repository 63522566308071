<script lang="ts">
  import { nrkCalendar, nrkChevronDown } from '@nrk/core-icons';
  import type { Question } from '@pin/schemas/clientSchema';
  import { format } from 'date-fns';
  import { nb } from 'date-fns/locale';
  import { state } from '../../api/combinedClientState.js';
  import CoreToggle from '../core/CoreToggle.svelte';
  import QuestionHistoryItem from './TodaysQuestionHistoryItem.svelte';

  export let questions: Question[];
</script>

<div class="wrapper">
  <button
    class="expand-button"
    type="button"
    data-for={`${$state.title}-today`}
  >
    <span class="event-name">{$state.title}</span>

    <div class="date-expand--wrapper">
      <div class="icon-text-wrapper">
        <span class="center">
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {@html nrkCalendar}
        </span>
        <span class="event-date"
          >{format($state.location.date, 'dd.MM.yy', {
            locale: nb,
          })}</span
        >
      </div>

      <span class="expand-icon center">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronDown}
      </span>
    </div>
  </button>

  <CoreToggle id={`${$state.title}-today`} closeOnOutsideClick={false}>
    {#if questions.length === 0}
      <div class="no-results-text">Ingen resultater enda</div>
    {:else}
      <div class="question-wrapper">
        {#each questions as question, i}
          <QuestionHistoryItem {question} isLast={i === questions.length - 1} />
        {/each}
      </div>
    {/if}
  </CoreToggle>
</div>

<style>
  .wrapper {
    padding: var(--300, 1rem) var(--300, 1rem) var(--300, 1rem) var(--300, 1rem);
    background: #1c0001;
    max-width: var(--max-width);
    width: 100%;
  }
  .expand-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .event-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .date-expand--wrapper {
    display: flex;
    gap: 0.5rem;
  }

  .event-date {
    width: 3.75rem;
  }

  .no-results-text {
    font-style: italic;
    width: 100%;
    text-align: left;
    margin-top: 1rem;
  }

  .question-wrapper {
    margin-top: 1rem;
    width: 100%;
  }

  .icon-text-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
</style>
