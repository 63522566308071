import type { ClientState } from '@pin/schemas/clientSchema';
import type { Observable } from 'rxjs';
import {
  NEVER,
  catchError,
  repeat,
  shareReplay,
  startWith,
  switchMap,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { isVisible$ } from '../util/rxjs.dom.js';
import { jsonSuccessSelector, retryFetch } from '../util/rxjs.http.js';
import { trackEvent } from '../util/snowplow.js';
import { getClientId } from './clientId.js';

export const emptyStateId = 'not-received';
const emptyState: ClientState = {
  id: emptyStateId,
  title: '',
  description: '',
  state: 'Open',
  questions: [],
  isNorwayLeagueEnabled: true,
  nextQuestionBatchAt: null,
  location: {
    name: '',
    date: '',
  },
  poster: null,
};

/**
 * Fetches client state on repeat.
 *
 * * refetches when document becomes visible
 * * refetches on an interval
 * * fetch is aborted on timeout
 * * fetch is aborted when document becomes hidden
 * * does not fetch when document is hidden
 * * retries on error with exponential backoff
 * * supports retry-after header
 * * starts with empty state
 * * clientId can be overridden with a query parameter `client-id`
 * * emits state one extra time when it transitions to closed
 *
 * @param options
 * @returns
 */
const clientStateObservable = ({
  retry = 3,
  timeout: timeoutMs = 10_000,
  errorDelay = 5_000,
  repeatDelay = 20_000,
}): Observable<ClientState> =>
  isVisible$
    .pipe(
      switchMap((visible) =>
        visible
          ? fetchState().pipe(
              timeout(timeoutMs),
              retryFetch(retry),
              catchError((_err: unknown, source) => {
                trackEvent({ action: 'error:fetching-clientstate' });
                return timer(errorDelay).pipe(switchMap(() => source));
              }),
              repeat({ delay: () => timer(repeatDelay) })
            )
          : NEVER
      )
    )
    .pipe(startWith(emptyState));

const fetchState = () =>
  fromFetch(`${import.meta.env.VITE_API_URL}/api/state/${getClientId()}`, {
    selector: jsonSuccessSelector<ClientState>,
  });

export const clientState$ = clientStateObservable({}).pipe(
  shareReplay({ bufferSize: 1, refCount: false })
);
