<script context="module" lang="ts">
  export type DialogCloseEvent = {
    openingElementWillBeRemoved?: boolean;
  };
</script>

<script lang="ts">
  import { nrkClose } from '@nrk/core-icons';
  import * as Sentry from '@sentry/svelte';
  import noScroll from 'no-scroll';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { shouldOpenLeaugeDialog } from '../../stores/openLeagueDialog.js';

  export let id: string;
  export let visible = false;

  export function show() {
    handleToggle(true);
  }

  export function close(e?: DialogCloseEvent | MouseEvent) {
    try {
      dialogElement?.close();
      dispatch('close', e);
      noScroll.off();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      visible = false;
      shouldOpenLeaugeDialog.set(false);
    }
  }

  let dialogElement: HTMLDialogElement;

  const dispatch = createEventDispatcher();

  const handleToggle = (shouldOpen: boolean) => {
    if (!dialogElement) {
      return;
    }
    if (shouldOpen) {
      try {
        dialogElement.showModal();
        noScroll.on();
      } catch (e) {
        Sentry.captureException(e);
      }
      return;
    } else {
      noScroll.off();
      close();
    }
  };

  const handleclick = (e: Event) => {
    if (e.target === dialogElement) {
      visible = false;
      handleToggle(visible);
    }
  };

  $: handleToggle(visible);

  onMount(() => {
    import('dialog-polyfill').then(({ default: dialogPolyfill }) => {
      dialogPolyfill.registerDialog(dialogElement);
    });
    if (visible) {
      handleToggle(visible);
    }
  });

  onDestroy(() => {
    noScroll.off();
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<dialog
  {id}
  bind:this={dialogElement}
  on:close={() => close()}
  on:click={handleclick}
>
  <div class="dialogcontent">
    <slot />
  </div>
  <button class="x" on:click={close} aria-label="Lukk">
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkClose}</button
  >
</dialog>

<style>
  dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    border: 0;
    border: transparent;
    background: transparent;
    padding: 0;
    border: transparent;
    border-radius: 1.875rem;
    box-shadow: 0.625rem 0.625rem 0 rgba(20, 20, 20, 0.372);
  }

  .dialogcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  dialog::backdrop,
  :global(dialog + .backdrop) {
    background: rgba(114, 12, 12, 0.459);
    backdrop-filter: blur(0.625rem);
    -webkit-backdrop-filter: blur(0.125rem);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  dialog:not([open]) {
    display: none;
  }

  .x {
    position: absolute;
    top: 1.85rem;
    right: 1.5rem;
    color: white;
    font-size: 1.3rem;
    mix-blend-mode: difference;
  }
</style>
