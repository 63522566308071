<script lang="ts">
  import CreateLeagueCard from './CreateLeagueCard.svelte';
  import Shelf from './Shelf.svelte';
  import SvgClock from './SVGClock.svelte';

  const hour = '09';
  const minute = '30';
</script>

<Shelf numberOfDots={2}>
  <div class="non-question-card card">
    Konkurransen starter onsdag
    <span>
      <SvgClock {hour} {minute} />
    </span>
    <span class="time"> kl. {hour}:{minute} </span>
  </div>
  <CreateLeagueCard>Lag en venneliga allerede nå!</CreateLeagueCard>
</Shelf>

<style>
  .time {
    font-size: 1.5rem;
  }
</style>
