<script lang="ts">
  import { onMount } from 'svelte';
  import { fetchLeaguesSubject, shouldFetchLeagues } from '../api/fetchleague';
  import {
    fetchScoreboardSubject,
    shouldFetchScoreboard,
  } from '../api/scoreboard';
  import { fetchTotalScore, shouldFetchTotalScore } from '../api/totalScore';
  import { hasSeenScoreboardInfo } from '../stores/infoStores.js';
  import Leagues from './League/Leagues.svelte';
  import { loginSessionStore } from './Login/loginSessionStore.js';
  import ScoreboardInfo from './ScoreboardInfo.svelte';
  import PublicLeagues from './Scores/PublicLeagues/PublicLeagues.svelte';

  onMount(() => {
    if ($shouldFetchScoreboard.shouldFetch) {
      fetchScoreboardSubject.next({
        cacheRefresh: $shouldFetchScoreboard.cacheRefresh,
      });
      shouldFetchScoreboard.next({ shouldFetch: false, cacheRefresh: false });
    }

    if ($shouldFetchLeagues.shouldFetch) {
      fetchLeaguesSubject.next({
        cacheRefresh: $shouldFetchLeagues.cacheRefresh,
      });
      shouldFetchLeagues.next({ shouldFetch: false, cacheRefresh: false });
    }

    if ($shouldFetchTotalScore.shouldFetch) {
      fetchTotalScore.next({
        cacheRefresh: $shouldFetchTotalScore.cacheRefresh,
      });
      shouldFetchTotalScore.next({ shouldFetch: false, cacheRefresh: false });
    }
  });
</script>

{#if !$hasSeenScoreboardInfo && $loginSessionStore?.isLoggedIn}
  <ScoreboardInfo />
{/if}
<PublicLeagues />
<Leagues />
