<script lang="ts">
  import { clientState$ } from '../../api/clientState.js';
  import { trackEvent } from '../../util/snowplow';
  import { loginModel } from '../Login/loginModel';
  import { loginSessionStore } from '../Login/loginSessionStore.js';
  import type CoreDialog from '../core/CoreDialog.svelte';

  export let welcomeDialog: CoreDialog;

  $: loginSession = $loginSessionStore;
  $: name = loginSession?.user?.name;
</script>

<div class="dialog-content dark-card">
  <div class="header">
    Velkommen til Gjettekonken{name ? `, ${name}!` : '!'}
  </div>
  <div class="subheader">
    {$clientState$.title}
  </div>

  <img src="Result-Trophy.svg" alt="Gull og gråfarget pokal med to håndtak" />

  <div class="text">
    Gi dine beste gjett på spørsmålene og konkurrer mot resten av Norge eller
    din egen venneliga. Nye konkurranser gjennom hele NM!
  </div>
  {#if !loginSession?.isLoggedIn}
    <div class="button-wrapper">
      <button
        class="primary-button"
        on:click={() => {
          loginModel.login();
          trackEvent({ action: 'click:login-welcome-message' });
          welcomeDialog.close();
        }}
      >
        Logg på NRK</button
      >
      <button class="close-button" on:click={welcomeDialog.close}
        >Ikke nå</button
      >
    </div>
  {:else}
    <button
      class="primary-button"
      on:click={() => {
        welcomeDialog.close();
      }}
    >
      Kom i gang
    </button>
  {/if}
</div>

<style>
  .dialog-content {
    justify-content: space-between;
    height: 18.75rem;
  }

  .header {
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .subheader {
    font-size: 1.2rem;
    color: #ffa90a;
    margin-bottom: -2rem;
  }

  img {
    margin-top: 1rem;
    height: 5rem;
  }

  /* .broadcast-wrapper {
    width: 100%;
    margin-top: 1rem;
  } */

  .text {
    text-align: left;
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1rem;
    font-style: normal;
    font-weight: 560;
    line-height: normal;
    padding: 1rem 0;
  }

  .button-wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
  .close-button {
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1rem;
    width: 100%;
    font-weight: 700;
    display: flex;
    height: var(--700, 3rem);
    padding: 0.75rem;
    justify-content: center;
    border-radius: 0.125rem;
    border: 0.0625rem solid var(--NRK-gray-100, #ebe7e6);
  }
</style>
