<script lang="ts">
  import type { ClientState } from '@pin/schemas/clientSchema';
  import { clientState$ } from '../api/clientState.js';
  import { totalScore$ } from '../api/totalScore';
  import {
    hasSolution,
    isAnswered,
    isClosed,
    isRevealed,
  } from '../util/questionState.js';
  import AfterCards from './AfterCards.svelte';
  import QuestionHistory from './History/QuestionHistory.svelte';
  import TodaysQuestionHistory from './History/TodaysQuestionHistory.svelte';
  import TrophyDetailed from './Icons/TrophyDetailed.svelte';
  import { loginSessionStore } from './Login/loginSessionStore';
  import Poster from './Poster.svelte';
  import Scores from './Scores.svelte';

  export let state: ClientState;

  $: history = state.questions
    .filter(
      (q) => isRevealed(q) || (isClosed(q) && !isAnswered(q) && hasSolution(q))
    )
    .slice()
    .reverse();
</script>

<div>
  {#if $clientState$.poster?.level === 'Info'}
    <div class="poster">
      <Poster poster={$clientState$.poster} />
    </div>
  {/if}

  <div class="trophy center">
    <TrophyDetailed />
  </div>

  <div class="congrats text">Gratulerer</div>
  <div class="name text">{$loginSessionStore?.user?.name?.split(' ')[0]}!</div>

  <span class="number">
    <span class="text-gradient" aria-hidden="true">{$totalScore$?.score}</span>
    {$totalScore$?.score}
  </span>
  <span class="number">
    <span class="text-gradient" aria-hidden="true">p</span>
    p
  </span>

  <Scores />

  <h2 class="pill-h2">Sees igjen til SKI-VM i februar</h2>
  <div class="shelf">
    <AfterCards noMoreQuestions={false} />
  </div>

  <div class="history-wrapper">
    <TodaysQuestionHistory questions={history} />
    <QuestionHistory />
  </div>
</div>

<style>
  * {
    max-width: var(--max-width);
    margin: 0 auto;
  }

  .poster {
    margin-top: 1rem;
  }
  .trophy {
    transform: scale(2);
    height: 6.25rem;
    margin: 1rem 0;
  }
  .text {
    color: var(--NRK-gray-900, #1d1d21);
    font-size: 1.875rem;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: 0.0375rem;
  }
  .congrats {
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0.0375rem;
  }
  h2 {
    max-width: fit-content;
    margin-top: 1rem;
    color: white;
    background: var(--NRK-red-500, #f34531);
  }

  .name {
    font-weight: 890;
    margin-bottom: 1rem;
  }

  .number,
  .text-gradient {
    letter-spacing: 0.2rem;
  }

  .number {
    position: relative;
    text-align: center;
    font-size: 3.75rem;
    font-weight: 700;
    background: linear-gradient(
      180deg,
      #ffaa05 0%,
      #e39706 38.63%,
      #462d08 46.68%,
      #e39706 55.26%,
      #ffaa05 78.87%,
      #462d08 91.21%,
      #ffaa05 100%
    );
    background-size: 100% 85%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.3125rem transparent;
    filter: drop-shadow(0rem 0rem 0.25rem #9842008a)
      drop-shadow(0rem 0rem 0.8125rem #9842008a);
  }

  .number .text-gradient {
    position: absolute;
    background: linear-gradient(
      152.14deg,
      #ffd600 0%,
      #faa005 40.96%,
      #eea400 49.3%,
      #ffc700 54.37%,
      #faa005 68.67%,
      #eca000 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.0625rem transparent;
    z-index: 2;
  }
  .history-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    margin-top: 1rem;
  }
</style>
