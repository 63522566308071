<script lang="ts">
  import { nrkWarning } from '@nrk/core-icons';
  import type { FirebaseDoc } from '@pin/schemas/adminSchema';
  import type { Poster } from '@pin/schemas/posterSchema';

  export let poster: Omit<Poster, keyof FirebaseDoc>;
</script>

<div class="wrapper">
  <h2>{poster.title}</h2>
  {#if poster.level !== 'Info'}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <div class="icon">{@html nrkWarning}</div>
  {/if}
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  <p>{@html poster.description}</p>
</div>

<style>
  .wrapper {
    padding: var(--400, 1.5rem) var(--300, 1rem);
    max-width: var(--max-width);
    margin: 0 auto;
    background: #1c0001;
    white-space: pre-wrap;
  }
  .icon {
    color: orange;
    transform: scale(1.5);
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 700;
  }
</style>
