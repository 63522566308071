import type { Question } from '@pin/schemas/clientSchema';
import type { Observable } from 'rxjs';
import { filter, map, pairwise } from 'rxjs';
import { writable } from 'svelte/store';
import { state } from './combinedClientState.js';

export const newQuestions$: Observable<Question[]> = state.pipe(
  // Extract the open questions from the state
  map((state) => state.questions.filter((q) => q.status === 'Open')),
  // Keep track of the current and previous emissions
  pairwise(),

  // Compare the previous and current arrays to find new questions
  map(([prevQuestions, currentQuestions]) => {
    const prevIds = new Set(prevQuestions.map((q) => q.id));
    return currentQuestions.filter((q) => !prevIds.has(q.id));
  }),

  // Emit only if there are new questions
  filter((newQuestions) => newQuestions.length > 0)
);

export const showToast = writable<boolean>(false);
