import type { ClientState, Question } from '@pin/schemas/clientSchema';
import {
  combineLatest,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
} from 'rxjs';
import { get } from 'svelte/store';
import selectedTabStore from '../stores/selectedTabStore';
import { hasSolution, isClosed } from '../util/questionState.js';
import { fetchAnswerSubject, userAnswers$ } from './answers';
import { clientState$ } from './clientState';
import { fetchLeaguesSubject, shouldFetchLeagues } from './fetchleague';
import { fetchHistory, shouldFetchHistory } from './history';
import { fetchScoreboardSubject, shouldFetchScoreboard } from './scoreboard';
import { fetchTotalScore, shouldFetchTotalScore } from './totalScore';

export const state = combineLatest([clientState$, userAnswers$]).pipe(
  map(([state, userAnswers]) => {
    return {
      ...state,
      questions: state.questions
        .map((question) => {
          const answerForQuestion = userAnswers.find(
            (answer) => answer.questionId === question.id
          );
          if (answerForQuestion == undefined) {
            return question;
          }
          return {
            ...question,
            answer: answerForQuestion,
          } as ClientState['questions'][number];
        })
        .sort((a, b) => {
          if (a.closeAt && b.closeAt) {
            return (
              new Date(a.closeAt).getTime() - new Date(b.closeAt).getTime()
            );
          } else if (a.closeAt) {
            return -1;
          } else if (b.closeAt) {
            return 1;
          } else {
            return 0;
          }
        }),
    };
  }),
  startWith(null),
  pairwise(),
  map(([previous, next]) => {
    compareState(previous, next);
    return next;
  }),
  filter((next) => next !== null),
  shareReplay({ bufferSize: 1, refCount: false })
);

const compareState = (prev: ClientState | null, next: ClientState | null) => {
  if (prev == null || next == null) {
    return;
  }

  if (
    prev.id !== next.id ||
    prev.closedEpisodeUpdatedAt !== next.closedEpisodeUpdatedAt
  ) {
    // Referesh all data when clientstate is completely new or old episode has been updated
    fetchScoreboardSubject.next({ cacheRefresh: true });
    fetchLeaguesSubject.next({ cacheRefresh: true });
    fetchTotalScore.next({ cacheRefresh: true });
    fetchAnswerSubject.next({ cacheRefresh: true });
    fetchHistory.next({ cacheRefresh: true });

    shouldFetchScoreboard.next({ shouldFetch: true, cacheRefresh: true });
    shouldFetchLeagues.next({ shouldFetch: true, cacheRefresh: true });
    shouldFetchTotalScore.next({ shouldFetch: true, cacheRefresh: true });
    shouldFetchHistory.next(true);
  } else if (
    getClosedCount(prev.questions) !== getClosedCount(next.questions)
  ) {
    // Immediately refresh scoreboards if user is already on results tab
    if (get(selectedTabStore).name === 'Liga') {
      fetchScoreboardSubject.next({ cacheRefresh: true });
      fetchLeaguesSubject.next({ cacheRefresh: true });
      fetchTotalScore.next({ cacheRefresh: true });
    } else {
      // Schedule scoreboards to be fetched next time user navigates to results tab
      shouldFetchScoreboard.next({ shouldFetch: true, cacheRefresh: true });
      shouldFetchLeagues.next({ shouldFetch: true, cacheRefresh: true });
      shouldFetchTotalScore.next({ shouldFetch: true, cacheRefresh: true });
    }
  }
};

const getClosedCount = (questions: Question[]): number => {
  return questions.reduce(
    (count, question) =>
      isClosed(question) && hasSolution(question) ? count + 1 : count,
    0
  );
};
