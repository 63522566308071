<script lang="ts">
  import { nrkChevronDown } from '@nrk/core-icons';
  import type { ScoreBoard } from '@pin/schemas/clientSchema';
  import { clientState$ } from '../../../api/clientState';
  import { impression, trackEvent } from '../../../util/snowplow';
  import NorwegianFlag from '../../common/icons/NorwegianFlag.svelte';
  import CoreToggle from '../../core/CoreToggle.svelte';
  import EmptyScoreboard from '../EmptyScoreboard.svelte';
  import NorwayScoreboardItem from './NorwayScoreboardItem.svelte';

  export let scoreboard: ScoreBoard | null = null;

  export let showLoginButton = true;

  $: isAfter = $clientState$.state === 'After';
  let showTotal = true;
  $: personalScore = scoreboard?.scores.find(
    ({ isCurrentUser }) => isCurrentUser
  );
</script>

<div class="league-wrapper" class:isAfter>
  <button class="expand-button" type="button" data-for="league-norgesliga">
    <span class="league-name">
      <span class="center flag">
        <NorwegianFlag />
      </span>
      Norgesliga
    </span>

    <span class="expand-icon center">
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkChevronDown}
    </span>
  </button>
  {#if isAfter && personalScore !== undefined}
    <div class="rank-wrapper">
      <span class="rank"> Nr. {personalScore.rank}</span> av {personalScore.numOfRanks}
    </div>
  {/if}
  <CoreToggle id="league-norgesliga" hidden={false} closeOnOutsideClick={false}>
    {#if scoreboard}
      <u-tabs>
        <u-tablist>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <u-tab
            aria-selected={!showTotal}
            on:click={() => {
              showTotal = false;
              trackEvent({ action: 'click:view-norwayleague-current' });
            }}
            aria-controls="tab-today">Resultater</u-tab
          >
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <u-tab
            aria-selected={showTotal}
            on:click={() => {
              showTotal = true;
              trackEvent({ action: 'click:view-norwayleague-total' });
            }}
            aria-controls="tab-total">Sammenlagt</u-tab
          >
        </u-tablist>

        <u-tabpanel id="tab-today" hidden={false}>
          <h3>{$clientState$.title}</h3>
          <!-- Need to check if it's the active tab or u-tab will mount all tabs at once -->
          {#if !showTotal}
            <NorwayScoreboardItem
              type="norway-today"
              scoreboard={{
                ...scoreboard,
                scores: scoreboard.scores
                  .filter(({ type }) => type === 'CURRENT')
                  .slice()
                  .sort((a, b) => a.rank - b.rank),
              }}
            />
          {/if}
        </u-tabpanel>
        <u-tabpanel id="tab-total" hidden={false}>
          <div class="spacer"></div>
          {#if showTotal}
            <NorwayScoreboardItem
              type="norway-total"
              scoreboard={{
                ...scoreboard,
                scores: scoreboard.scores
                  .filter(({ type }) => type === 'TOTAL')
                  .slice()
                  .sort((a, b) => a.rank - b.rank),
              }}
            />
          {/if}
        </u-tabpanel>
      </u-tabs>
    {:else if showLoginButton}
      <EmptyScoreboard showLoginButton />
    {:else}
      <u-tabs use:impression={'norway-league'}>
        <u-tablist>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <u-tab
            aria-selected={!showTotal}
            on:click={() => {
              showTotal = false;
              trackEvent({ action: 'click:view-norwayleague-current' });
            }}
            aria-controls="tab-today"
          >
            Resultater
          </u-tab>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <u-tab
            aria-selected={showTotal}
            on:click={() => {
              showTotal = true;
              trackEvent({ action: 'click:view-norwayleague-total' });
            }}
            aria-controls="tab-total"
          >
            Sammenlagt
          </u-tab>
        </u-tablist>

        <u-tabpanel id="tab-today" hidden={false}>
          <!-- Need to check if it's the active tab or u-tab will mount all tabs at once -->
          {#if !showTotal}
            <EmptyScoreboard />
          {/if}
        </u-tabpanel>
        <u-tabpanel id="tab-total" hidden={false}>
          {#if showTotal}
            <EmptyScoreboard />
          {/if}
        </u-tabpanel>
      </u-tabs>
    {/if}
  </CoreToggle>
</div>

<style>
  .league-wrapper {
    margin: 1rem 0;
    background: #1c0001;
  }

  .league-wrapper.isAfter {
    border: 0.125rem solid #a05c0e;
    background: black;
    margin-top: 1rem;
  }

  u-tabs {
    width: 100%;
    margin-top: 2rem;
  }
  u-tablist {
    display: flex;
    padding: 0 1rem;
  }
  u-tab {
    display: grid;
    place-content: center;
    padding: 0.5625rem var(--300, 1rem);
    width: 50%;
    font-weight: 560;
    letter-spacing: -0.01rem;
    background: #472527;
    color: #ff9e8f;
  }

  u-tab:nth-child(1) {
    border-radius: 0.625rem 0 0 0.625rem;
  }
  u-tab:nth-child(2) {
    border-radius: 0 0.625rem 0.625rem 0;
  }
  u-tab[aria-selected='true'] {
    color: #131317;
    background: #ffa90a;
  }

  h3 {
    font-size: 1rem;
    margin-top: 1rem;
  }

  .spacer {
    /* same height as the h3 */
    height: 2.1875rem;
  }

  .expand-button {
    color: var(--NRK-gray-100, #ebe7e6);
    font-family: 'NRK Sans Variable';
    font-size: 1.313rem;
    font-weight: 800;
    letter-spacing: 0.0262rem;
    padding: 1rem;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    gap: 0.3rem;
    align-items: center;
    position: relative;
  }

  .rank-wrapper {
    padding: 0 1rem 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: -0.01em;
  }
  .rank {
    color: #ffa90a;
  }

  .league-name {
    display: inline-flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    position: relative;
  }

  .flag {
    width: 2.0625rem;
  }
</style>
