import type { ScoreBoard } from '@pin/schemas/clientSchema';
import {
  BehaviorSubject,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  timeout,
  timer,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { getFreshSession$ } from '../components/Login/loginSessionStore';
import { jsonSuccessSelector, retryFetch } from '../util/rxjs.http';
import { trackEvent } from '../util/snowplow';
import { getClientId } from './clientId';
import { clientState$ } from './clientState';

export const shouldFetchScoreboard = new BehaviorSubject<{
  shouldFetch: boolean;
  cacheRefresh: boolean;
}>({ shouldFetch: true, cacheRefresh: false });
export const fetchScoreboardSubject = new Subject<{ cacheRefresh: boolean }>();
const scoreboardObservable = () =>
  fetchScoreboardSubject.pipe(
    switchMap(({ cacheRefresh }) =>
      from(getFreshSession$).pipe(
        switchMap((session) =>
          clientState$.pipe(
            map(({ isNorwayLeagueEnabled, poster }) => ({
              isNorwayLeagueEnabled,
              canFetch: poster?.level !== 'Error',
            })),
            distinctUntilChanged(
              (prev, curr) =>
                prev.isNorwayLeagueEnabled === curr.isNorwayLeagueEnabled &&
                prev.canFetch === curr.canFetch
            ),
            filter(({ canFetch }) => canFetch),
            switchMap(({ isNorwayLeagueEnabled }) => {
              if (!isNorwayLeagueEnabled) {
                return of(null);
              }
              return session?.accessToken != null
                ? fromFetch(
                    `${import.meta.env.VITE_API_URL}/api/authenticated/${getClientId()}/scoreboard`,
                    {
                      headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${session.accessToken}`,
                      },
                      cache:
                        cacheRefresh || shouldFetchScoreboard
                          ? 'reload'
                          : 'default',
                      selector: jsonSuccessSelector<ScoreBoard>,
                    }
                  ).pipe(
                    timeout(5_000),
                    retryFetch(3),
                    catchError((_err: unknown, source) => {
                      trackEvent({ action: 'error:fetching-scoreboard' });
                      return timer(5_000).pipe(switchMap(() => source));
                    })
                  )
                : of(null);
            })
          )
        )
      )
    )
  );

export const scoreboard$ = scoreboardObservable().pipe(
  shareReplay({ bufferSize: 1, refCount: false })
);
