<script lang="ts">
  import type { SimpleLeague } from '@pin/schemas/leagueSchema';
  import { leagues$ } from '../../api/fetchleague';
  import { shouldOpenLeaugeDialog } from '../../stores/openLeagueDialog.js';
  import { impression, trackEvent } from '../../util/snowplow';
  import { loginSessionStore } from '../Login/loginSessionStore';
  import CoreDialog from '../core/CoreDialog.svelte';
  import CreateLeagueDialog from './CreateLeagueDialog.svelte';
  import ShareDialog from './ShareDialog.svelte';

  let dialog: CoreDialog;
  let league: SimpleLeague | null;

  const shareNewLeague = (newLeague: SimpleLeague) => {
    league = newLeague;
  };

  $: if (
    $shouldOpenLeaugeDialog &&
    dialog &&
    $leagues$ &&
    $leagues$.length < 10
  )
    dialog.show();

  $: if ($leagues$ && $leagues$.length >= 10) shouldOpenLeaugeDialog.set(false);
</script>

{#if $loginSessionStore?.isLoggedIn && $leagues$ != null && $leagues$.length < 10}
  <div class="box">
    {#if $leagues$ && $leagues$?.length > 0}
      <h3 class="header">Lag din egen venneliga!</h3>
    {:else}
      <h2 class="header">Lag din egen venneliga!</h2>
    {/if}
    <p class="text">
      Konkurrer mot venner og familie. Hvem i gjengen er best på gjetting?
    </p>
    <button
      class="tertiary-button"
      use:impression={'open-create-league-button'}
      on:click={() => {
        dialog.show();
        trackEvent({ action: 'click:createLeague' });
      }}
      ><span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1583_11161)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.79858 1.69847C4.76399 1.14138 4.74621 0.574608 4.74621 0H20.0324C20.0324 0.574608 20.0146 1.14138 19.98 1.69847H24.2785C24.2785 6.41092 21.0998 10.4325 16.6256 12.0171C15.637 13.2632 14.4862 14.0857 13.246 14.3472C13.3824 17.3327 15.3341 19.8441 18.0259 20.8062L18.3337 21.2308V26.3262H6.4445V21.2308L6.74703 20.8062C9.43901 19.8441 11.3957 17.3373 11.5323 14.3471C10.2921 14.0855 9.14146 13.2631 8.15303 12.0172C3.67876 10.4326 0.5 6.41097 0.5 1.69847H4.79858ZM17.8707 10.0595C18.8599 8.13671 19.5682 5.69542 19.8701 2.97233H22.9146C22.4881 5.97753 20.5705 8.55609 17.8707 10.0595ZM6.90777 10.0593C5.9186 8.13654 5.21035 5.69532 4.90847 2.97233H1.86426C2.2907 5.97739 4.20813 8.55586 6.90777 10.0593Z"
              fill="#FFA90A"
            />
          </g>
          <defs>
            <clipPath id="clip0_1583_11161">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </span>Opprett venneliga</button
    >
  </div>
{/if}
<CoreDialog
  bind:this={dialog}
  id="create-league"
  on:close={() => (league = null)}
>
  <div>
    {#if league != null}
      <ShareDialog {league} />
    {:else}
      <CreateLeagueDialog {shareNewLeague} />
    {/if}
  </div>
</CoreDialog>

<style>
  .box {
    padding: 1rem 2rem;
    margin: 1rem 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #1c0001;
    height: 14rem;
  }

  .text {
    text-align: left;
  }

  .header {
    all: unset;
    font-weight: 700;
    font-size: 1.5rem;
  }

  button {
    min-width: 14rem;
    padding: 0.6rem 0;
    font-weight: 700;
    display: inline-flex;
    justify-content: center;
    gap: 0.3rem;
    align-items: center;
    position: relative;
  }
</style>
