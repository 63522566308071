<script lang="ts">
  import type { LoginSession } from '@nrk/innlogging-web/redirect-login-client';
  import type { ScoreBoard } from '@pin/schemas/clientSchema';
  import { loginSessionStore } from '../../Login/loginSessionStore';
  import EmptyScoreboard from '../EmptyScoreboard.svelte';
  import Scoreboard from '../Scoreboard.svelte';

  export let scoreboard: ScoreBoard;
  export let type: 'norway-total' | 'norway-today';

  const replaceCurrentUserNameInScoreboard = (
    scoreboard: ScoreBoard,
    session: LoginSession | null
  ) =>
    scoreboard.scores.map((score) => ({
      ...score,
      name:
        score.isCurrentUser && session?.user?.name != null
          ? session.user.name
          : score.name,
    }));

  $: numOfRanks = scoreboard.scores[0]?.numOfRanks ?? 0;
  $: rank = scoreboard.scores.find((score) => score.isCurrentUser)?.rank;
</script>

{#if scoreboard.scores.length === 0}
  <div class="center">
    <EmptyScoreboard />
  </div>
{:else}
  <Scoreboard
    {type}
    scoreboard={replaceCurrentUserNameInScoreboard(
      scoreboard,
      $loginSessionStore
    )}
  />
  {#if rank != null}
    <div class="overall-rank">
      Du er <span class="highlight">nr. {rank}</span> av totalt {numOfRanks}
      {numOfRanks === 1 ? 'deltaker' : 'deltakere'}
    </div>
  {:else}
    <div class="overall-rank">
      Det er totalt {numOfRanks}
      {numOfRanks === 1 ? 'deltaker' : 'deltakere'}
    </div>
  {/if}
{/if}

<style>
  .highlight {
    color: orange;
  }

  .overall-rank {
    position: relative;
    margin: 2rem 0;
    font-weight: 700;
    padding: 1rem;
    z-index: 3;
  }
</style>
