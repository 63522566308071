<script lang="ts">
  import { nrkUserLoggedin, nrkUserNotloggedin } from '@nrk/core-icons';
  import { nrkLogoNrk } from '@nrk/core-icons/logo';
  import { loginModel } from './loginModel';
  import { loginSessionStore } from './loginSessionStore';
</script>

<header>
  <a href="https://www.nrk.no" aria-label="NRK">
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkLogoNrk}
  </a>
  {#if $loginSessionStore?.user?.name == null}
    <button
      class="nrk-user"
      data-testid="login-button"
      on:click={() => {
        loginModel.login();
      }}
      aria-label="Logg på"
    >
      <!-- eslint-disable svelte/no-at-html-tags -->
      {@html nrkUserNotloggedin}
      <span>Logg på NRK</span>
    </button>
  {:else}
    <button
      class="nrk-user"
      on:click={() => {
        loginModel.goToUserPage();
      }}
      aria-label={`Til brukerside for ${$loginSessionStore.user?.name}`}
    >
      <!-- eslint-disable svelte/no-at-html-tags -->
      {@html nrkUserLoggedin}
      <span> {$loginSessionStore.user?.name} </span>
    </button>
  {/if}
</header>
{#if import.meta.env.DEV}
  {#await import('./loginDialog/LocalLoginDialog.svelte') then LocalLoginDialog}
    <svelte:component this={LocalLoginDialog.default} />
  {/await}
{/if}

<style>
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;
    width: 100%;
    margin: 0.25rem 0;
  }

  a {
    display: block;
    padding: 1.25rem;
    font-size: 1.6rem;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .nrk-user {
    padding: 1.25rem;
    display: inline-flex;
    align-items: center;
    text-transform: unset;
    font-weight: 350;
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  .nrk-user:hover {
    text-decoration: underline;
  }

  .nrk-user span {
    margin-left: 0.5rem;
    max-width: 9rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
