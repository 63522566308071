import { HttpError, parseRetryAfterHeader } from '@pin/client-http-utils';
import {
  catchError,
  from,
  retry,
  switchMap,
  throwError,
  timer,
  type OperatorFunction,
} from 'rxjs';

export const catchHttpErrors = <T>(
  statusCodes: number[]
): OperatorFunction<unknown, T> => {
  return catchError((error: unknown) => {
    if (
      error instanceof HttpError &&
      statusCodes.includes(error.response.status)
    ) {
      return from<Promise<T>>(error.response.json()).pipe(
        catchError(() => {
          return throwError(
            () => new Error('Failed to parse error response body')
          );
        })
      );
    }
    return throwError(() => error);
  }) as OperatorFunction<unknown, T>;
};

export const checkNoContentSuccess = () => {
  return switchMap((res: Response) => {
    if (!res.ok) {
      return throwError(() => new HttpError(res));
    }
    return from(Promise.resolve(res));
  });
};

export function jsonSuccessSelector<T = any>(response: Response): Promise<T> {
  if (!response.ok) {
    throw new HttpError(response);
  }
  return response.json();
}

export function retryFetch<T>(count: number) {
  return retry<T>({
    count,
    delay: (error, retryCount) => {
      const exponentialBackoff = 1000 * retryCount ** 2;
      if (error instanceof HttpError) {
        return timer(
          Math.max(
            parseRetryAfterHeader(error.response) ?? 0,
            exponentialBackoff
          )
        );
      }
      return timer(exponentialBackoff);
    },
    resetOnSuccess: true,
  });
}
