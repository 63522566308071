<script lang="ts">
  import { state } from '../../api/combinedClientState.js';
  import { currentQuestionId } from '../../util/questionStateStore.js';
  import Pill from '../Pill.svelte';
  import MultipleChoiceQuestion from '../Questions/MultipleChoiceQuestion.svelte';
  import ValueQuestion from '../Questions/ValueQuestion.svelte';
  import type CoreDialog from '../core/CoreDialog.svelte';

  export let questionDialog: CoreDialog;
  const getCurrentQuestion = (
    questionId: string,
    questions: typeof $state.questions
  ) => {
    if (questionId == null) {
      return;
    }
    return questions.find((q) => q.id === questionId);
  };

  let previousQuestionId: string | undefined;
  $: currentQuestion = getCurrentQuestion($currentQuestionId, $state.questions);
  $: {
    if ($currentQuestionId != previousQuestionId) {
      previousQuestionId = $currentQuestionId;
    }
  }

  const getColor = () => {
    if (currentQuestion?.score === 500) return 'gold';
    if (currentQuestion?.score === 250) return 'silver';
    return 'bronze';
  };
</script>

{#key $currentQuestionId}
  <div class="dialog-content light-card">
    {#if currentQuestion}
      <Pill question={currentQuestion} big />
      {#if currentQuestion.type === 'MultipleChoice'}
        <div class="question">
          <MultipleChoiceQuestion {questionDialog} question={currentQuestion} />
        </div>
      {:else}
        <div class="question">
          <ValueQuestion {questionDialog} question={currentQuestion} />
        </div>
      {/if}
    {/if}
    <div class={getColor()}>{`Opptil ${currentQuestion?.score} poeng`}</div>
  </div>
{/key}

<style>
  .dialog-content {
    display: grid;
    justify-items: center;
    grid-template-columns: 16.4375rem;
    padding-bottom: 0;
    grid-template-rows: 1.5rem auto 2.5rem;
    background: linear-gradient(
        204deg,
        rgba(255, 255, 255, 0.5) 1.82%,
        rgba(255, 255, 255, 0) 100%
      ),
      var(--NRK-gray-100, #ebe7e6);
    color: var(--NRK-gray-950, #131317);
  }

  .question {
    width: 100%;
  }

  .gold,
  .silver,
  .bronze {
    align-self: flex-end;
    width: 20.4375rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 560;
    line-height: normal;
  }
</style>
