<script lang="ts">
  export let selected = false;
  export let orange = false;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill={orange ? '#FFA90A' : selected ? '#ffa90a' : '#FFF2EE'}
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.29858 1.69847C4.26399 1.14138 4.24621 0.574608 4.24621 0H19.5324C19.5324 0.574608 19.5146 1.14138 19.48 1.69847H23.7785C23.7785 6.41092 20.5998 10.4325 16.1256 12.0171C15.137 13.2632 13.9862 14.0857 12.746 14.3472C12.8824 17.3327 14.8341 19.8441 17.5259 20.8062L17.8337 21.2308V23H5.9445V21.2308L6.24703 20.8062C8.93901 19.8441 10.8957 17.3373 11.0323 14.3471C9.79214 14.0855 8.64146 13.2631 7.65303 12.0172C3.17876 10.4326 0 6.41097 0 1.69847H4.29858ZM17.3707 10.0595C18.3599 8.13671 19.0682 5.69542 19.3701 2.97233H22.4146C21.9881 5.97753 20.0705 8.55609 17.3707 10.0595ZM6.40777 10.0593C5.4186 8.13654 4.71035 5.69532 4.40847 2.97233H1.36426C1.7907 5.97739 3.70813 8.55586 6.40777 10.0593Z"
  />
</svg>
