<script lang="ts">
  import { nrkClose } from '@nrk/core-icons';
  import { newQuestions$, showToast } from '../../api/newQuestions.js';

  import { fade, fly } from 'svelte/transition';
  import { clientState$ } from '../../api/clientState';
  import selectedTabStore, { tabs } from '../../stores/selectedTabStore.js';
  import Cards from '../Icons/Cards.svelte';

  let questions = 0;
  newQuestions$.subscribe((newQuestions) => {
    showToast.set(true);
    questions = newQuestions.length;
  });

  $: if (questions === 0) showToast.set(false);
</script>

{#if $showToast && !$clientState$.poster}
  <div
    class="toast"
    in:fly={{ y: 200, duration: 300 }}
    out:fade={{ duration: 200 }}
    aria-live="polite"
  >
    <button
      class="test"
      on:click={() => {
        $selectedTabStore = tabs[0];
        showToast.set(false);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
      }}
    >
      <span class="button-content">
        <Cards black />
        {questions === 1 ? 'Ett nytt spørsmål' : `${questions} nye spørsmål`}
      </span>
    </button>
    <button class="x-button" on:click={() => showToast.set(false)}>
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      {@html nrkClose}
    </button>
  </div>
{/if}

<style>
  .button-content {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  .toast {
    position: fixed;
    z-index: 10;
    bottom: 5vh;
    right: 50%;
    transform: translateX(50%);
    width: 95%;
    max-width: 25rem;
    display: grid;
    padding: 1.125rem 2.625rem;
    place-content: center;
    gap: 0.625rem;
    font-weight: 700;
    line-height: 130%; /* 1.3rem */
    letter-spacing: -0.01rem;
    color: var(--NRK-gray-950, #131317);
    border: var(--100, 0.25rem) solid #ef9d00;
    background: linear-gradient(
        0deg,
        rgba(255, 107, 0, 0.2) 0%,
        rgba(255, 107, 0, 0.2) 100%
      ),
      linear-gradient(
        160deg,
        #ffd600 1.1%,
        #ffc700 34.66%,
        #934600 54.45%,
        #eca000 61.33%,
        #ffd600 87.14%
      );
    box-shadow:
      0rem -0.125rem 1.1875rem 0rem rgba(255, 81, 0, 0.49),
      0rem -0.0626rem 0.5943rem 0rem rgba(255, 81, 0, 0.37),
      0rem -0.0377rem 0.358rem 0rem rgba(255, 81, 0, 0.32),
      0rem -0.0241rem 0.2294rem 0rem rgba(255, 81, 0, 0.28),
      0rem -0.0156rem 0.1487rem 0rem rgba(255, 81, 0, 0.25),
      0rem -0.0099rem 0.0936rem 0rem rgba(255, 81, 0, 0.21),
      0rem -0.0057rem 0.0537rem 0rem rgba(255, 81, 0, 0.17),
      0rem -0.0025rem 0.0237rem 0rem rgba(255, 81, 0, 0.12);

    background-size: 200% 100%;
    animation: gradient 30s ease infinite;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  .x-button {
    position: absolute;
    right: 0.5rem;
    top: 1.1rem;
  }
</style>
