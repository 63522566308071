<script lang="ts">
  import { nrkLock } from '@nrk/core-icons';
  import type { Question } from '@pin/schemas/clientSchema';
  import { isClosed, isClosing } from '../util/questionState.js';
  import LogoShape from './Icons/LogoShape.svelte';
  export let question: Question | undefined = undefined;
  export let dark = true;
  export let big = false;
  $: closing = isClosing(question);
  $: closed = isClosed(question);
</script>

{#if closing}
  <div class="closing-pill" class:dark class:big>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkLock} <span>Låses snart!</span>
  </div>
{:else if closed}
  <div class="closing-pill" class:dark class:big>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html nrkLock} <span>Låst</span>
  </div>
{:else}
  <LogoShape {big} />
{/if}

<style>
  .closing-pill {
    display: flex;
    padding: var(--375, 0.9rem) var(--400, 1rem);
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    height: 1.25rem;
    width: auto;
    border-radius: 62rem;
    color: var(--NRK-gray-950, #131317);
    background: #ebe7e6;
    font-size: 0.8rem;
    margin-top: -0.45rem;
  }

  .closing-pill.big {
    margin-top: 0;
  }

  span {
    margin-left: -0.4rem;
    margin-bottom: -0.1rem;
  }

  .dark {
    background: var(--NRK-gray-950, #131317);
    color: #ebe7e6;
  }
</style>
