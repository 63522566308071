<script lang="ts">
  import { nrkCheck, nrkSpinner } from '@nrk/core-icons';
  import type {
    Alternative,
    AnswerSubmit,
    MultipleChoiceQuestion,
  } from '@pin/schemas/clientSchema';
  import * as Sentry from '@sentry/svelte';
  import { onMount } from 'svelte';
  import { fetchAnswerSubject } from '../../api/answers.js';
  import { submitAnswer } from '../../api/submitAnswer.js';
  import { delay } from '../../util/delay.js';
  import { isClosed } from '../../util/questionState.js';
  import { trackEvent } from '../../util/snowplow.js';
  import { openedQuestionKey, setItem } from '../../util/storage.js';
  import { loginModel } from '../Login/loginModel.js';
  import { loginSessionStore } from '../Login/loginSessionStore';
  import type CoreDialog from '../core/CoreDialog.svelte';

  export let question: MultipleChoiceQuestion;
  export let questionDialog: CoreDialog;
  export let answered = false;

  $: selected = question.answer?.alternative.id;
  let questiontext: HTMLDivElement | null;
  let isSubmitting = false;
  let error = false;
  let success = false;

  const handleSubmit = async (alternative: Alternative) => {
    if (
      $loginSessionStore == undefined ||
      $loginSessionStore.accessToken == undefined
    ) {
      trackEvent({ action: 'click:choice-guess-redirected-to-login' });
      setItem('session', openedQuestionKey, question.id);

      loginModel.login();
      return;
    }

    trackEvent({
      action: `click:submit-answer-choice-guess-${answered ? 'answered' : 'unanswered'}`,
    });

    selected = alternative.id;
    isSubmitting = true;
    error = false;

    try {
      const payload: AnswerSubmit = {
        questionId: question.id,
        value: alternative.id,
      };

      await submitAnswer(payload);
      trackEvent({ action: 'interaction' });
      await showSuccessThenClose();
    } catch (e) {
      Sentry.captureException(e);
      trackEvent({ action: 'error:submit-answer-choice-guess' });
      error = true;
    } finally {
      isSubmitting = false;
    }
  };

  const showSuccessThenClose = async () => {
    success = true;
    isSubmitting = false;
    await delay(1_001);
    success = false;
    fetchAnswerSubject.next({ cacheRefresh: true });
    questionDialog.close({ openingElementWillBeRemoved: answered === false });
  };
  $: closed = isClosed(question);

  const calculateFontSize = (text?: string) => {
    const maxLength = 10; // Define the length at which font size is minimal
    const minFontSize = 2; // Minimum font size
    const maxFontSize = 3.75; // Maximum font size
    if (text == undefined) {
      return maxFontSize;
    }
    return Math.max(
      minFontSize,
      maxFontSize -
        Math.floor((text.length / maxLength) * (maxFontSize - minFontSize))
    );
  };

  onMount(() => {
    if (questiontext != null) {
      questiontext.focus();
    }
  });
</script>

<div class="content">
  <div>
    <div class="question-text" tabindex="-1" bind:this={questiontext}>
      {question.text}
    </div>

    {#if answered}
      <div>
        {#if question.answer?.alternative.text}
          <div class="answer-header">{closed ? 'Du svarte' : 'Du gjetter'}</div>
          <div
            class="answer"
            style={`--font-size: ${calculateFontSize(question.answer?.alternative.text)}rem;`}
          >
            {question.answer?.alternative.text}
          </div>
        {/if}
      </div>
    {/if}
  </div>
  {#if !closed}
    <div class="button-wrapper">
      {#each question.alternatives as alternative, i (alternative.id)}
        <button
          class="secondary-button"
          class:answered
          class:selected={alternative.id === selected}
          class:success={alternative.id === selected && success}
          class:error={alternative.id === selected && error}
          data-test-id={`submit-answer-${i}`}
          on:click|preventDefault={() => handleSubmit(alternative)}
          disabled={isSubmitting}
          aria-busy={isSubmitting}
          title="Trykk for å sende inn ditt svar"
          aria-label="Gjett på {alternative.text}"
          >{#if alternative.id === selected}
            <span class="checkmark">
              <!-- eslint-disable-next-line svelte/no-at-html-tags -->
              {@html nrkCheck}
            </span>
          {/if}
          {#if alternative.id === selected && success}
            <span aria-live="assertive"> Svar registrert </span>
          {:else if alternative.id === selected && error}
            <span aria-live="assertive"> Prøv igjen </span>
          {:else}
            {alternative.text}
          {/if}
          {#if isSubmitting && alternative.id === selected}
            <span class="inline-spinner">
              <!-- eslint-disable-next-line svelte/no-at-html-tags -->
              {@html nrkSpinner}
            </span>
          {/if}
        </button>
      {/each}
    </div>
  {/if}
</div>

<style>
  .content,
  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content {
    height: 100%;
    justify-content: space-between;
  }

  .question-text {
    margin-top: 1rem;
    text-align: left;
  }

  .question-text:focus {
    box-shadow: none;
  }

  .answer-header {
    margin-top: 2rem;
    font-size: 1.313rem;
    font-weight: 890;
  }

  .answer {
    font-size: var(--font-size, 3.5rem);
    font-weight: 800;
    color: var(--NRK-cool-orange-500, #ffa90a);
  }

  .button-wrapper {
    margin-top: 1.5rem;
    gap: 0.625rem;
    width: fit-content;
  }

  button {
    min-width: 12rem;
    font-size: 1rem;
    max-width: 12rem;
  }

  .success {
    background: var(--NRK-cool-green-400, #5dc479);
    color: white;
  }

  .error {
    background: var(--NRK-cool-orange-400, #feb937);
    color: var(--NRK-black, #000);
  }

  .secondary-button:last-of-type {
    margin-bottom: 1rem;
  }

  .checkmark {
    display: inline-flex;
    font-size: 1.2rem;
  }
</style>
